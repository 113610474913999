import { Button, Form, Input, Select, Skeleton, Tabs, Tag, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { orderBy } from 'lodash'
import { presetPalettes } from '@ant-design/colors'
import { useParams, useSearchParams } from 'react-router-dom'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import React, { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { useGetContentsQuery, useGetModelsQuery, usePostToolsRendersMutation } from 'shared/api/chatApi'
import { useGetDocumentsQuery } from 'shared/api/documentApi'
import { useGetToolQuery } from 'shared/api/toolApi'

import ChatContentContainer from 'modules/chat/components/content/ChatContentContainer'
import ToolIcon from 'modules/tools/components/icon/ToolIcon'

import chat_avatar from 'assets/images/chat_avatar.svg'
import pdf from 'assets/images/pdf.svg'

dayjs.extend(LocalizedFormat)

const ToolRendererPage = () => {
	const params = useParams()
	let [searchParams, setSearchParams] = useSearchParams()

	const [isHoverId, setIsHoverId] = useState()

	const { data: tool, isFetching: isGetToolFetching } = useGetToolQuery(params?.id)
	const [postToolsRenders, { isLoading: isPostToolsRendersLoading }] = usePostToolsRendersMutation()
	const { data: historyData } = useGetContentsQuery({
		filter_field: 'prompt_tpl_id',
		filter_value: tool?.id,
		offset: 0,
		limit: 100,
	})
	const { data: documentData } = useGetDocumentsQuery({
		refetchOnMountOrArgChange: true,
	})

	const currentChatDocuments = useMemo(() => {
		const ids = searchParams.getAll('ids')
		return documentData?.filter((document) => ids?.includes(document.id))
	}, [documentData, searchParams])

	const { data: modelData } = useGetModelsQuery()

	const [currentModel, setCurrentModel] = useState()

	useEffect(() => {
		setCurrentModel(modelData?.[0]?.id)
	}, [modelData])

	const isReady = !isGetToolFetching && tool?.id

	const _onFinish = async (values) => {
		postToolsRenders({
			model_id: currentModel,
			placeholder_params: values,
			prompt_tpl_id: tool?.id,
			render_source_type: 'tool',
			document_ids: currentChatDocuments?.map((i) => i.id),
			conversation_id: tool?.id,
			with_kb_contexts: false,
			message: 'string',
		})
	}

	const renderFormItem = (formItem, i) => {
		switch (formItem.data_type) {
			case 'dt_string':
				return (
					<Form.Item name={formItem.name} label={formItem.description} key={i}>
						<Input.TextArea rows={1} />
					</Form.Item>
				)
			case 'dt_single_selections':
				return (
					<Form.Item name={formItem.name} label={formItem.description} key={i}>
						<Select options={formItem?.data_values?.map((i) => ({ value: i, label: i }))} />
					</Form.Item>
				)
			case 'dt_multi_selections':
				return (
					<Form.Item name={formItem.name} label={formItem.description} key={i}>
						<Select options={formItem?.data_values?.map((i) => ({ value: i, label: i }))} />
					</Form.Item>
				)
			default:
				return null
		}
	}

	return (
		<div className="flex space-x-4">
			<div className="w-[358px]">
				<div className="card p-6">
					{isReady ? (
						<div className="flex flex-col space-y-2">
							<div className="flex items-center justify-between">
								<ToolIcon type={tool.logo_id} color={Object.values(presetPalettes)[7][4]} />
								<div className="space-x-1">
									{tool.tags?.map((tag, j) => (
										<Tag color={['volcano', 'blue', 'green'][j % 3]} key={j}>
											{tag}
										</Tag>
									))}
								</div>
							</div>
							<div className="text-xl font-medium">{tool.title}</div>
							<div>
								<Typography.Text>{tool.description}</Typography.Text>
							</div>
							<Form layout="vertical" onFinish={_onFinish}>
								{tool.placeholders?.map((item, i) => renderFormItem(item, i))}
								<div>
									<label>Select a model :</label>
									<Select
										value={currentModel}
										onSelect={(v) => setCurrentModel(v)}
										style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
										options={modelData?.map((v) => ({ value: v.id, label: v.display_name }))}
									/>
									<p
										id="model-helper-text"
										style={{
											color: 'gray',
											fontStyle: 'italic',
											marginTop: '10px',
											marginBottom: '30px',
										}}
									>
										Please select a self-hosted model when dealing with sensible content.
									</p>
								</div>
								<div className="grid grid-flow-row auto-rows-max gap-2 mb-2">
									{currentChatDocuments?.map((data, i) => (
										<div
											key={i}
											className="bg-[#F5F5F5] p-2 relative"
											onMouseOver={() => setIsHoverId(data?.metadata.document_id)}
											onMouseLeave={() => setIsHoverId()}
										>
											{isHoverId === data?.metadata.document_id && (
												<div
													className="absolute right-[-14px] top-[-16px] inline-block"
													onClick={() => {
														const ids = currentChatDocuments
															.filter((item) => item?.metadata?.document_id !== data?.metadata.document_id)
															.map((i) => i?.metadata?.document_id)

														setSearchParams({ ids })
													}}
												>
													<Button icon={<CloseCircleOutlined />} type="link" />
												</div>
											)}
											<div className="flex space-x-2 flex-wrap items-center">
												<img src={pdf} alt="pdf" />
												<span className="text-sm">{data?.metadata?.filename}</span>
											</div>
										</div>
									))}
								</div>

								<Form.Item>
									<Button
										type="primary"
										htmlType="submit"
										className="w-full"
										loading={isPostToolsRendersLoading}
										disabled={isPostToolsRendersLoading}
									>
										Generate
									</Button>
								</Form.Item>
								<Form.Item>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											padding: '10px', // Add padding for spacing
										}}
									>
										<Typography.Text type="secondary">v {tool.version}</Typography.Text>
									</div>
								</Form.Item>
							</Form>
						</div>
					) : (
						<Skeleton />
					)}
				</div>
			</div>
			<div className="flex-1">
				<div className="space-y-4">
					<div className="card px-6">
						<Tabs
							size="large"
							items={[
								{ key: 'new', label: 'New' },
								{ key: 'history', label: 'History' },
							]}
						/>
					</div>
					<div className="card p-6">
						{historyData
							? orderBy(historyData, 'updated_at', 'desc')
									.filter((i) => i.role !== 'user')
									.map((item, i) => {
										const icon = <img src={chat_avatar} alt="chat" />

										return (
											<div key={i} className={'py-4 px-6 bg-[#FAFAFA] space-x-4 flex items-start'}>
												<span className="min-w-[30px]">{icon}</span>
												<div className="w-full">
													<Typography.Text type="secondary">{dayjs(item.created_at).format('LLL')}</Typography.Text>
													<ChatContentContainer isResponse={true} item={item} />
												</div>
											</div>
										)
									})
							: 'Use the toolbox on the left to generate content'}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ToolRendererPage
