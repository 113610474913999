import { apiKB } from './index'

export const documentApi = apiKB.injectEndpoints({
	endpoints: (build) => ({
		queryDocuments: build.mutation({
			providesTags: () => ['query_documents'],
			query: (body) => {
				return { url: `queries/`, method: 'POST', body }
			},
		}),

		getDocuments: build.query({
			providesTags: () => ['documents'],
			query: ({ params }) => ({
				url: 'documents/',
				params,
			}),
		}),

		deleteDocument: build.mutation({
			invalidatesTags: () => ['documents', 'query_documents'],
			query: (id) => ({ url: `documents/${id}`, method: 'DELETE' }),
		}),
	}),
})

export const { useDeleteDocumentMutation, useQueryDocumentsMutation, useGetDocumentsQuery } = documentApi
