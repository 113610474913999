import { Outlet } from 'react-router-dom'
import Header from 'shared/components/layout/Header'
import React from 'react'

import * as Styled from 'shared/components/layout/__styles__/Layout.styles'
import AuthFooter from './AuthFooter'

const AuthLayout = () => {
	return (
		<Styled.Root className="font-sans">
			<Header />
			<Styled.Content>
				<Outlet />
			</Styled.Content>
			<AuthFooter />
		</Styled.Root>
	)
}

export default AuthLayout
