import { RiDatabase2Line } from 'react-icons/ri'
import { Typography } from 'antd'
import React from 'react'

const SaveGenerator = (document, i) => {
	return (
		<div className="card p-6 max-w-[288px] min-h-[260px] flex flex-col">
			<div className="flex items-center justify-between">
				<Typography.Text type="secondary">{document.created_at}</Typography.Text>
				<RiDatabase2Line fontSize="18px" color="#5A47CD" />
			</div>
			<div className="mt-2 mb-4 flex-1">
				<Typography.Text>{document.content}</Typography.Text>
			</div>
			<div className="flex items-center space-x-2">
				<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M2.34766 15.75V14.625H15.8477V15.75H2.34766ZM2.34766 12.6562V11.5312H6.47266V12.6562H2.34766ZM2.34766 9.5625V8.4375H6.47266V9.5625H2.34766ZM2.34766 6.46875V5.34375H6.47266V6.46875H2.34766ZM2.34766 3.375V2.25H15.8477V3.375H2.34766ZM8.16016 12.8438V5.15625H15.8477V12.8438H8.16016ZM9.28516 11.7188H14.7227V6.28125H9.28516V11.7188Z"
						fill="#49A847"
					/>
				</svg>
			</div>
		</div>
	)
}

export default SaveGenerator
