import { Form, Input, Select, Skeleton, Tabs, Tag, Typography } from 'antd'
import { presetPalettes } from '@ant-design/colors'
import React, { useState } from 'react'

import ToolIcon from 'modules/tools/components/icon/ToolIcon'

const colors = ['volcano', 'blue', 'green']

const ViewToolCard = ({ tool }) => {
	const [activeKey, setActiveKey] = useState('preview')

	const renderFormItem = (formItem, i) => {
		switch (formItem.data_type) {
			case 'dt_string':
				return (
					<Form.Item label={formItem.description} key={i}>
						<Input.TextArea rows={1} />
					</Form.Item>
				)
			case 'dt_single_selections':
				return (
					<Form.Item label={formItem.description} key={i}>
						<Select
							defaultValue={formItem?.data_values?.[0]}
							options={formItem?.data_values?.map((i) => ({ value: i, label: i }))}
						/>
					</Form.Item>
				)
			case 'dt_multi_selections':
				return (
					<Form.Item label={formItem.description} key={i}>
						<Select
							defaultValue={formItem?.data_values?.[0]}
							options={formItem?.data_values?.map((i) => ({ value: i, label: i }))}
						/>
					</Form.Item>
				)
			default:
				return null
		}
	}

	const renderTab = () => {
		switch (activeKey) {
			case 'preview':
				return (
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<ToolIcon type={tool.logo_id} color={Object.values(presetPalettes)[7][4]} />
							<div className="space-x-1">
								{tool.tags?.map((tag, j) => (
									<Tag color={colors[j % colors.length]} key={j}>
										{tag}
									</Tag>
								))}
							</div>
						</div>
						<div className="text-xl font-medium">{tool.title}</div>
						<div>
							<Typography.Text>{tool.description}</Typography.Text>
						</div>
						<Form layout="vertical">{tool.placeholders?.map((item, i) => renderFormItem(item, i))}</Form>
					</div>
				)
			case 'prompt':
				return <div>{tool.template}</div>
			case 'version':
				return (
					<div>
						<Typography.Text type="secondary">v {tool.version}</Typography.Text>
					</div>
				)
			default:
				return null
		}
	}

	return (
		<div className="space-y-4">
			<div className="card px-6">
				<Tabs
					size="large"
					activeKey={activeKey}
					onChange={setActiveKey}
					items={[
						{ key: 'preview', label: 'Preview' },
						{ key: 'prompt', label: 'Prompt template' },
						{ key: 'version', label: 'Version' },
					]}
				/>
			</div>
			<div className="card p-6">{tool ? renderTab() : <Skeleton />}</div>
		</div>
	)
}

export default ViewToolCard
