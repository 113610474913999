import { Button, Popconfirm, Skeleton, Switch, Tag, Typography } from 'antd'
import { presetPalettes } from '@ant-design/colors'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import ToolIcon from 'modules/tools/components/icon/ToolIcon'

import { useDeleteToolMutation, useGetToolsCategoriesQuery, useGetToolsQuery } from 'shared/api/toolApi'
import { useGetUserQuery } from 'shared/api/userApi'

const colors = ['volcano', 'blue', 'green']

const ToolGeneratorCards = ({ orderby, categoryId }) => {
	const navigate = useNavigate()

	const { data: toolsData, isFetching: isGetToolsFetching } = useGetToolsQuery({
		order_by: orderby,
		order: 'desc',
	})
	const { data: categories } = useGetToolsCategoriesQuery()
	const { data: user } = useGetUserQuery()

	const [deleteTool] = useDeleteToolMutation()

	const [displayedTools, setDisplayedTools] = useState()

	useEffect(() => {
		setDisplayedTools(categoryId ? toolsData.filter((t) => t.category_id === categoryId) : toolsData)
	}, [toolsData, categoryId])

	return isGetToolsFetching ? (
		<div className="flex flex-wrap gap-4 justify-between">
			{['', '', '', ''].map((item, i) => (
				<div key={i} className="w-[358px] card p-6 space-y-2 min-h-[222px] flex flex-col">
					<div className="flex items-center justify-between" key={i}>
						<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M3.06497 23.7L1.43164 22.5L7.69831 12.5L11.684 17.1667L16.9983 8.56669L20.6316 13.9667C20.2761 14.0111 19.9316 14.0834 19.5983 14.1834C19.265 14.2834 18.9316 14.4 18.5983 14.5334L17.0983 12.2334L12.0131 20.5334L7.96497 15.8334L3.06497 23.7ZM29.3316 30.6667L24.865 26.2C24.3983 26.5334 23.8928 26.7889 23.3483 26.9667C22.8039 27.1445 22.2428 27.2334 21.665 27.2334C20.0909 27.2334 18.7529 26.682 17.6511 25.5794C16.5492 24.4768 15.9983 23.1379 15.9983 21.5628C15.9983 19.9876 16.5496 18.65 17.6522 17.55C18.7548 16.45 20.0937 15.9 21.6689 15.9C23.2441 15.9 24.5816 16.4509 25.6816 17.5528C26.7816 18.6547 27.3316 19.9926 27.3316 21.5667C27.3316 22.1445 27.2372 22.7056 27.0483 23.25C26.8594 23.7945 26.6094 24.3161 26.2983 24.815L30.765 29.2334L29.3316 30.6667ZM21.6603 25.2334C22.6856 25.2334 23.5539 24.8794 24.265 24.1714C24.9761 23.4634 25.3316 22.5967 25.3316 21.5714C25.3316 20.546 24.9777 19.6778 24.2697 18.9667C23.5617 18.2556 22.695 17.9 21.6697 17.9C20.6443 17.9 19.7761 18.254 19.065 18.962C18.3539 19.67 17.9983 20.5366 17.9983 21.562C17.9983 22.5873 18.3523 23.4556 19.0603 24.1667C19.7683 24.8778 20.6349 25.2334 21.6603 25.2334ZM24.1316 14.3C23.7983 14.1667 23.4539 14.0778 23.0983 14.0334C22.7428 13.9889 22.3761 13.9445 21.9983 13.9L29.1316 2.66669L30.765 3.86669L24.1316 14.3Z"
								fill="#13C2C2"
							/>
						</svg>
						<div className="space-x-1">
							<Tag color="magenta">...</Tag>
						</div>
					</div>
					<div className="text-xl font-medium">
						<Skeleton.Input active size="small" />
					</div>
					<div className="flex-1">
						<Typography.Text>
							<Skeleton.Input active />
						</Typography.Text>
					</div>
					<div>
						<Typography.Text type="secondary">
							v 0.18 <Skeleton.Button active size="small" /> views
						</Typography.Text>
					</div>
				</div>
			))}
		</div>
	) : (
		<div className="flex flex-wrap gap-4 justify-between after:content-[''] after:flex-[0_1_358px]">
			{displayedTools?.map((item, i) => {
				return (
					<div
						onClick={() => {
							navigate(`/app/tools/render/${item.id}`)
						}}
						key={i}
						className="bg-[#eaecf2d9] cursor-pointer hover:shadow-md rounded-lg h-full"
					>
						<div className="w-[358px] card px-6 pt-6 pb-4 space-y-2 min-h-[200px] flex flex-col">
							<div className="flex items-center justify-between">
								<ToolIcon type={item.logo_id} color={Object.values(presetPalettes)[i][5]} />
								<div className="space-x-1">
									{item.tags?.map((tag, j) => (
										<Tag color={colors[j % colors.length]} key={j}>
											{tag}
										</Tag>
									))}
								</div>
							</div>
							<div className="text-xl font-medium">{item.title}</div>
							<div className="flex-1">
								<Typography.Text>{item.description}</Typography.Text>
							</div>
							<div className="flex items-center justify-between">
								<div>{categories?.find((i) => i.id === item.category_id)?.category || 'Other'}</div>
								<div className="flex items-center justify-between text-xs">
									<span className="text-[#00000073] bg-[#f5f5f5] py-0.5 px-1 rounded">NEW</span>
									<span className="mx-2">
										<Typography.Text type="secondary">v {item.version}</Typography.Text>
									</span>
									<Typography.Text type="secondary">{item.nb_users} usages</Typography.Text>
								</div>
							</div>
						</div>
						{(user.id === item.user_id || user.is_superuser) && (
							<div className="px-6 pt-4 pb-4 flex items-center justify-between">
								<div className="flex items-center justify-between">
									<Button
										type="link"
										onClick={(e) => {
											e.stopPropagation()
											navigate(`/app/tools/${item.id}?step=info`)
										}}
										className="px-2"
									>
										Edit
									</Button>
									<Popconfirm
										title="Delete the tool"
										description="Are you sure to delete this tool?"
										onConfirm={(e) => {
											e.stopPropagation()
											deleteTool(item.id)
										}}
										onCancel={(e) => {
											e.stopPropagation()
										}}
										okText="Yes"
										cancelText="No"
									>
										<Button
											onClick={(e) => {
												e.stopPropagation()
											}}
											type="link"
											danger
											className="px-2"
										>
											Delete
										</Button>
									</Popconfirm>
								</div>
								<Switch
									// checkedChildren="Enabled"
									// unCheckedChildren="Disabled"
									// checked={true}
									onChange={(_, e) => {
										e.stopPropagation()
									}}
								/>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}

export default ToolGeneratorCards
