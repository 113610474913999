import { Navigate, Outlet } from 'react-router-dom'
import { selectToken } from 'modules/root/selectors/rootSelectors'
import { useSelector } from 'react-redux'

const PrivateRoutes = () => {
	const token = useSelector(selectToken)
	return token ? <Outlet /> : <Navigate to="/auth/login/" />
}

export default PrivateRoutes
