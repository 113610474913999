export default [
	{
		icon: (
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M24 42C18.9667 42 14.7083 41.225 11.225 39.675C7.74167 38.125 6 36.2333 6 34V14C6 11.8 7.75833 9.91667 11.275 8.35C14.7917 6.78333 19.0333 6 24 6C28.9667 6 33.2083 6.78333 36.725 8.35C40.2417 9.91667 42 11.8 42 14V34C42 36.2333 40.2583 38.125 36.775 39.675C33.2917 41.225 29.0333 42 24 42ZM24 17.6C26.8667 17.6 29.8083 17.1583 32.825 16.275C35.8417 15.3917 37.7833 14.4 38.65 13.3C37.75 12.2333 35.7917 11.25 32.775 10.35C29.7583 9.45 26.8333 9 24 9C21.0667 9 18.1167 9.43333 15.15 10.3C12.1833 11.1667 10.2333 12.1667 9.3 13.3C10.2333 14.4667 12.1667 15.475 15.1 16.325C18.0333 17.175 21 17.6 24 17.6ZM23.95 28.3C25.35 28.3 26.75 28.225 28.15 28.075C29.55 27.925 30.8917 27.7 32.175 27.4C33.4583 27.1 34.6833 26.7333 35.85 26.3C37.0167 25.8667 38.0667 25.3833 39 24.85V17.1C38.0333 17.6333 36.9667 18.1167 35.8 18.55C34.6333 18.9833 33.4 19.35 32.1 19.65C30.8 19.95 29.4667 20.1833 28.1 20.35C26.7333 20.5167 25.35 20.6 23.95 20.6C22.55 20.6 21.15 20.5167 19.75 20.35C18.35 20.1833 17.0083 19.95 15.725 19.65C14.4417 19.35 13.225 18.9833 12.075 18.55C10.925 18.1167 9.9 17.6333 9 17.1V24.85C9.9 25.3833 10.9167 25.8667 12.05 26.3C13.1833 26.7333 14.3917 27.1 15.675 27.4C16.9583 27.7 18.3 27.925 19.7 28.075C21.1 28.225 22.5167 28.3 23.95 28.3ZM24 39C25.6 39 27.25 38.8583 28.95 38.575C30.65 38.2917 32.2083 37.9167 33.625 37.45C35.0417 36.9833 36.2417 36.4667 37.225 35.9C38.2083 35.3333 38.8 34.75 39 34.15V27.9C38.0667 28.4333 37.0167 28.9083 35.85 29.325C34.6833 29.7417 33.4583 30.1 32.175 30.4C30.8917 30.7 29.5583 30.925 28.175 31.075C26.7917 31.225 25.3833 31.3 23.95 31.3C22.5167 31.3 21.1 31.225 19.7 31.075C18.3 30.925 16.9583 30.7 15.675 30.4C14.3917 30.1 13.1833 29.7417 12.05 29.325C10.9167 28.9083 9.9 28.4333 9 27.9V34.2C9.16667 34.7667 9.73333 35.3417 10.7 35.925C11.6667 36.5083 12.8667 37.025 14.3 37.475C15.7333 37.925 17.3 38.2917 19 38.575C20.7 38.8583 22.3667 39 24 39Z"
					fill="#5A47CD"
				/>
			</svg>
		),
		title: 'Centralized Knowledge Management',
		description:
			'Manage a diverse set of knowledge sources within your company, including documents, databases, images, and videos. Powerful preprocessing functionalities integrate, analyze, and categorize content for swift retrieval.',
	},
	{
		icon: (
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M39.8 41.95L26.65 28.8C25.65 29.6666 24.484 30.3416 23.1521 30.825C21.8201 31.3083 20.4027 31.55 18.9 31.55C15.2946 31.55 12.2433 30.3 9.74595 27.8C7.24865 25.3 6 22.2833 6 18.75C6 15.2166 7.25 12.2 9.75 9.69995C12.25 7.19995 15.275 5.94995 18.825 5.94995C22.375 5.94995 25.3917 7.19995 27.875 9.69995C30.3583 12.2 31.6 15.2191 31.6 18.7575C31.6 20.1858 31.3667 21.5666 30.9 22.9C30.4333 24.2333 29.7333 25.4833 28.8 26.65L42 39.75L39.8 41.95ZM18.85 28.55C21.5583 28.55 23.8604 27.5916 25.7563 25.675C27.6521 23.7583 28.6 21.45 28.6 18.75C28.6 16.05 27.6521 13.7416 25.7563 11.825C23.8604 9.90828 21.5583 8.94995 18.85 8.94995C16.1139 8.94995 13.7882 9.90828 11.8729 11.825C9.95763 13.7416 9 16.05 9 18.75C9 21.45 9.95763 23.7583 11.8729 25.675C13.7882 27.5916 16.1139 28.55 18.85 28.55Z"
					fill="#5A47CD"
				/>
			</svg>
		),
		title: 'Advanced Search and Interactive Chatbot',
		description:
			'Employ our one-click search engine to effortlessly find the required content in your knowledge base, and engage and analyze selected content with a chatbot powered by GPT technology.',
	},
	{
		icon: (
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M33.95 24.6999L23.3 14.0499L33.95 3.3999L44.6 14.0499L33.95 24.6999ZM6 21.0999V6.0499H21.05V21.0999H6ZM26.9 41.9999V26.9499H41.95V41.9999H26.9ZM6 41.9999V26.9499H21.05V41.9999H6ZM9 18.0999H18.05V9.0499H9V18.0999ZM34.1 20.6499L40.55 14.1999L34.1 7.7499L27.65 14.1999L34.1 20.6499ZM29.9 38.9999H38.95V29.9499H29.9V38.9999ZM9 38.9999H18.05V29.9499H9V38.9999Z"
					fill="#5A47CD"
				/>
			</svg>
		),
		title: 'Customizable Prompt Tools',
		description:
			'Create custom tools based on your requirements with our user-friendly drag and-drop editor. We also provide a selection of pre-built tools, including content generation and image creation.',
	},
	{
		icon: (
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M6 36V33H32V36H6ZM39.9 33.4L30.45 23.95L39.85 14.55L42 16.7L34.75 23.95L42.05 31.25L39.9 33.4ZM6 25.4V22.4H26V25.4H6ZM6 15V12H32V15H6Z"
					fill="#5A47CD"
				/>
			</svg>
		),
		title: 'Knowledge Enrichment and Hierarchical Management',
		description:
			'Users can add content generated by platform tools to their knowledge base, and manage it effectively at company, team, and individual levels, ensuring continuous growth of the knowledge base.',
	},
	{
		icon: (
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M20.6 34.05L32.1 22.55L30.1 20.55L20.65 30L15.65 25L13.6 27.05L20.6 34.05ZM12.55 40C9.61667 40 7.125 38.975 5.075 36.925C3.025 34.875 2 32.3834 2 29.45C2 26.85 2.83333 24.5667 4.5 22.6C6.16667 20.6334 8.28333 19.45 10.85 19.05C11.5167 15.8167 13.0833 13.175 15.55 11.125C18.0167 9.07505 20.8667 8.05005 24.1 8.05005C27.8333 8.05005 30.9833 9.40838 33.55 12.125C36.1167 14.8417 37.4 18.1 37.4 21.9V23.1C39.8 23.0334 41.8333 23.8084 43.5 25.425C45.1667 27.0417 46 29.0834 46 31.55C46 33.85 45.1667 35.8334 43.5 37.5C41.8333 39.1667 39.85 40 37.55 40H12.55ZM12.55 37H37.55C39.05 37 40.3333 36.4667 41.4 35.4C42.4667 34.3334 43 33.05 43 31.55C43 30.05 42.4667 28.7667 41.4 27.7C40.3333 26.6334 39.05 26.1 37.55 26.1H34.4V21.9C34.4 18.8667 33.3833 16.3 31.35 14.2C29.3167 12.1 26.8333 11.05 23.9 11.05C20.9667 11.05 18.475 12.1 16.425 14.2C14.375 16.3 13.35 18.8667 13.35 21.9H12.4C10.3333 21.9 8.58333 22.625 7.15 24.075C5.71667 25.525 5 27.3167 5 29.45C5 31.55 5.73333 33.3334 7.2 34.8C8.66667 36.2667 10.45 37 12.55 37Z"
					fill="#5A47CD"
				/>
			</svg>
		),
		title: 'On-Premises Deployment and Data Security',
		description:
			'Deploy the platform in your private cloud or on-premises data center, ensuring data security. We offer sensitive information monitoring and automatic data anonymization to prevent data leakage',
	},
	{
		icon: (
			<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M4 17.9875C4 11.367 9.35308 6 16.0013 6H31.9986C38.6268 6 44 11.3896 44 17.9875V42H16.0013C9.37318 42 4 36.6104 4 30.0126V17.9875ZM40 38V17.9875C40 13.5908 36.4098 10 31.9986 10H16.0013C11.5692 10 8 13.5692 8 17.9875V30.0126C8 34.4092 11.5902 38 16.0013 38H40ZM28 22H32V26H28V22ZM16 22H20V26H16V22Z"
					fill="#5A47CD"
				/>
			</svg>
		),
		title: 'GPT Integration and Custom Large Language Model Support',
		description:
			'Seamlessly integrate OpenAI’s GPT technology, or opt to deploy your own large language models (LLMs), supporting data-driven model optimization.',
	},
]
