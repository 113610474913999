import { Form, Input, Modal, Popover, Select, Typography } from 'antd'
import { RiFunctionLine } from 'react-icons/ri'
import { presetPalettes } from '@ant-design/colors'
import React, { useEffect, useState } from 'react'

import { useGetModelsQuery, usePostConversationsMutation, usePostToolsRendersMutation } from 'shared/api/chatApi'
import { useGetToolsQuery } from 'shared/api/toolApi'

import ToolIcon from 'modules/tools/components/icon/ToolIcon'

const ToolRender = ({ currentChatItem, setCurrentChatItem }) => {
	const [toolModalOpen, setToolModalOpen] = useState({ isOpen: false, id: null })
	const [currentModel, setCurrentModel] = useState()
	const [isPopover, setIsPopover] = useState(false)

	const [form] = Form.useForm()

	const { data: toolsData } = useGetToolsQuery({
		order_by: 'popularity',
		order: 'desc',
	})
	const { data: modelData } = useGetModelsQuery()
	const [postToolsRenders, { isLoading: isPostToolsRendersLoading }] = usePostToolsRendersMutation()
	const [postConversations] = usePostConversationsMutation()

	useEffect(() => {
		setCurrentModel(modelData?.[0]?.id)
	}, [modelData])

	const renderFormItem = (formItem, i) => {
		switch (formItem.data_type) {
			case 'dt_string':
				return (
					<Form.Item name={formItem.name} label={formItem.description} key={i}>
						<Input.TextArea rows={2} />
					</Form.Item>
				)
			case 'dt_single_selections':
				return (
					<Form.Item name={formItem.name} label={formItem.description} key={i}>
						<Select options={formItem?.data_values?.map((i) => ({ value: i, label: i }))} />
					</Form.Item>
				)
			case 'dt_multi_selections':
				return (
					<Form.Item name={formItem.name} label={formItem.description} key={i}>
						<Select options={formItem?.data_values?.map((i) => ({ value: i, label: i }))} />
					</Form.Item>
				)
			default:
				return null
		}
	}

	const _onClose = () => {
		form.resetFields()
		setToolModalOpen({ isOpen: false, id: null })
	}

	const _onOk = () => {
		form.validateFields().then(async (values) => {
			if (currentChatItem) {
				await postToolsRenders({
					model_id: currentModel,
					placeholder_params: values,
					prompt_tpl_id: toolModalOpen?.tool?.id,
					render_source_type: 'chatbot',
					conversation_id: currentChatItem,
					with_kb_contexts: false,
					message: 'string',
				})
				setToolModalOpen({ isOpen: false, id: null })
			} else {
				const newConversation = await postConversations({
					title: toolModalOpen?.tool.title,
					prompt_tpl_id: '',
				})

				await postToolsRenders({
					model_id: currentModel,
					placeholder_params: values,
					prompt_tpl_id: toolModalOpen?.tool?.id,
					render_source_type: 'chatbot',
					conversation_id: newConversation?.data?.conversation_id,
					with_kb_contexts: false,
					message: 'string',
				})

				setCurrentChatItem(newConversation?.data?.conversation_id)
				setToolModalOpen({ isOpen: false, id: null })
			}
		})
	}

	return (
		<div
			className="border-r border-solid border-[#D9D9D9] pr-2 cursor-pointer"
			onClick={() => {
				if (!toolModalOpen.isOpen) {
					setIsPopover(true)
				}
			}}
		>
			<Popover
				open={isPopover}
				overlayInnerStyle={{ padding: 0 }}
				content={
					<div className="bg-[#F3F4F9] p-4 grid grid-cols-4 gap-4">
						{toolsData?.map((tool, i) => (
							<div
								key={tool.id}
								className="max-w-[88px] cursor-pointer"
								onClick={(e) => {
									e.stopPropagation()
									setIsPopover(false)
									setToolModalOpen({ id: tool.id, isOpen: true, tool })
								}}
							>
								<div className="h-[88px] w-[88px] rounded-lg bg-white text-center pt-[26px] mb-2 hover:shadow-md">
									<ToolIcon type={tool.logo_id} fontSize="36px" color={Object.values(presetPalettes)[i][5]} />
								</div>
								<div className="text-center">{tool.title}</div>
							</div>
						))}
					</div>
				}
			>
				<RiFunctionLine className="h-6 w-6 text-[#00000073]" />{' '}
			</Popover>

			<Modal
				open={toolModalOpen.isOpen}
				onCancel={_onClose}
				confirmLoading={isPostToolsRendersLoading}
				onOk={_onOk}
				okButtonProps={{ disabled: isPostToolsRendersLoading }}
				okText="Generate"
				preserve={false}
				destroyOnClose
				width="358px"
			>
				<Form layout="vertical" form={form}>
					<Typography.Title level={4}>{toolModalOpen?.tool?.title}</Typography.Title>
					{toolModalOpen?.tool?.placeholders?.map((item, i) => renderFormItem(item, i))}
					<div>
						<label>Select a model :</label>
						<Select
							value={currentModel}
							onSelect={(v) => setCurrentModel(v)}
							style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
							options={modelData?.map((v) => ({ value: v.id, label: v.display_name }))}
						/>
						<p
							id="model-helper-text"
							style={{
								color: 'gray',
								fontStyle: 'italic',
								marginTop: '10px',
								marginBottom: '30px',
							}}
						>
							Please select a self-hosted model when dealing with sensible content.
						</p>
					</div>
				</Form>
			</Modal>
		</div>
	)
}

export default ToolRender
