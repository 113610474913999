import styled from '@xstyled/styled-components'

export const Root = styled.div`
	padding: 16px;
`
export const PaginationButton = styled.div`
	margin-top: 120px;
	position: absolute;
	background-color: white;
	width: 40px;
	height: 40px;
	box-shadow: 0px 4px 15px 0px #00000040;
	border-radius: 4px;
	padding: 12px;
	border: 1px solid #d9d9d9;
	right: ${(props) => (props.next ? '24px' : 'unset')};
	cursor: pointer;
`
export const FavoriteToolCard = styled.div`
	width: 88px;
	height: 88px;
	border-radius: 8px;
	border: 1px;
	padding: 30px;
	background-color: white;
	cursor: pointer;
`
export const FavoriteText = styled.div`
	max-width: 88px;
	text-overflow: clip;
	text-align: center;
	margin-top: 8px;
`
export const ToolsBadge = styled.div`
	background-color: white;
	border-radius: 50%;
	width: 28px;
	height: 25px;
	font-size: 13px;
	text-align: center;
	margin-top: 2px;
`
