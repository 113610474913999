import { apiKB } from './index'

export const fileApi = apiKB.injectEndpoints({
	endpoints: (build) => ({
		addFile: build.mutation({
			invalidatesTags: () => ['stats', 'documents', 'query_documents'],
			query: (body) => {
				return { url: 'files/', method: 'POST', body, headers: { 'Content-Type': undefined } }
			},
		}),
	}),
})

export const { useAddFileMutation } = fileApi
