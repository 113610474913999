import { Button, Divider, Form, Input, Modal, Select, Upload, message, modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDisclosure } from '@chakra-ui/react'
import React, { useRef, useState } from 'react'

import { useAddCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery } from 'shared/api/categoryApi'
import { useAddFileMutation } from 'shared/api/fileApi'

const AddKnowledgeFormModal = ({ onCancel, ...props }) => {
	const { data: categoriesData, isFetching: isGetCategoriesFetching } = useGetCategoriesQuery()
	const [addCategory, { isLoading: isAddCategoryLoading }] = useAddCategoryMutation()
	const [deleteCategory] = useDeleteCategoryMutation()

	const [addFile, { isLoading: isAddFileLoading }] = useAddFileMutation()
	const [form] = Form.useForm()

	const [categoryName, setCategoryName] = useState('')
	const [categoryDescription, setCategoryDescription] = useState('')

	const inputRef = useRef(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const onCategoryNameChange = (event) => {
		setCategoryName(event.target.value)
	}

	const onCategoryDescriptionChange = (event) => {
		setCategoryDescription(event.target.value)
	}

	const addItem = async (e) => {
		e.preventDefault()
		const res = await addCategory({ category: categoryName, description: categoryDescription })
		setCategoryName('')
		setCategoryDescription('')
		if (res.data) {
			message.success('Successfully added your category!')
		}
		setTimeout(() => {
			inputRef.current?.focus()
		}, 0)
	}

	const _onOk = () => {
		form.validateFields().then(async (values) => {
			const currentCategory = categoriesData.find((item) => item.category_id === values.category)
			const file = new FormData()
			file.append('file', values?.file?.[0]?.originFileObj)
			file.append(
				'metadata',
				JSON.stringify({
					user_id: currentCategory.user_id,
					team_id: currentCategory.team_id,
					category: currentCategory.category,
				})
			)

			const res = await addFile(file)
			if (res.data) {
				message.success('Successfully added to knowledge base!')
			} else {
				message.error('Failed to add to the knowledge base!')
			}
			_onClose()
		})
	}

	const getFile = (e) => {
		if (Array.isArray(e)) {
			return e
		}
		return e && e.fileList
	}

	const _onClose = () => {
		form.resetFields()
		onClose()
	}

	return (
		<>
			<Button type="primary" onClick={onOpen} className="flex items-center">
				<PlusOutlined />
				Add document
			</Button>
			<Modal
				open={isOpen}
				onCancel={_onClose}
				confirmLoading={isAddFileLoading}
				onOk={_onOk}
				preserve={false}
				destroyOnClose
				title="Add document to knowledge base"
				{...props}
			>
				<Form layout="vertical" form={form}>
					<Form.Item
						label="Choose a category"
						name="category"
						rules={[{ required: true, message: 'Please select a category' }]}
					>
						<Select
							loading={isGetCategoriesFetching}
							placeholder="Select a category"
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider className="my-2" />
									<div className="space-y-2 p-4">
										<h2>If not found, create a new category :</h2>
										<Input
											placeholder="Please enter a new category name"
											ref={inputRef}
											value={categoryName}
											onChange={onCategoryNameChange}
										/>
										<Input.TextArea
											placeholder="Please enter the category description"
											value={categoryDescription}
											onChange={onCategoryDescriptionChange}
										/>
										<Button loading={isAddCategoryLoading} className="flex items-center" onClick={addItem}>
											<PlusOutlined />
											Add
										</Button>
									</div>
								</>
							)}
							optionLabelProp="label"
						>
							{categoriesData?.map((item) => (
								<Select.Option key={item.category_id} value={item.category_id} label={item.category}>
									<div className="flex items-center justify-between">
										{item.category}
										<Button
											danger
											type="text"
											onClick={(e) => {
												e.stopPropagation()
												modal.confirm({
													title: 'Delete the selected category',
													content: 'Are you sure to delete this category?',
													okText: 'Yes',
													okType: 'danger',
													cancelText: 'No',
													onCancel() {},
													onOk() {
														deleteCategory(item.category_id)
													},
												})
											}}
										>
											Delete
										</Button>
									</div>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Choose a file"
						valuePropName="fileList"
						name="file"
						getValueFromEvent={getFile}
						rules={[{ required: true, message: 'Please upload your file!' }]}
					>
						<Upload.Dragger
							beforeUpload={() => false}
							accept=".csv,.doc,.docx,.enex,.epub,.html,.md,.odt,.pdf,.ppt,.pptx,.txt"
							listType="picture"
							maxCount={1}
						>
							Click or drag file to this area to upload
						</Upload.Dragger>
					</Form.Item>
					<Form.Item>
						Please note that the analysis process takes time. You will be informed in the notification section.
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default AddKnowledgeFormModal
