import { createSlice } from '@reduxjs/toolkit'

const dashboardSlice = createSlice({
	name: 'Dashboard',
	initialState: { isReady: false },
	reducers: {
		startup(state) {},
		startupSuccess(state) {
			state.isReady = true
		},
		startupFailure() {},
	},
})

export const { startupSuccess, startup, startupFailure } = dashboardSlice.actions

export default dashboardSlice.reducer
