import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Tabs } from 'antd'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useState } from 'react'

import AddToolForm from 'modules/tools/components/form/AddToolForm'
import ViewToolCard from 'modules/tools/components/card/ViewToolCard'

const CreateToolPage = () => {
	const [currentTool, setCurrentTool] = useState()

	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const _onFormChange = (values) => setCurrentTool(values)

	return (
		<div className="space-y-4">
			<div className="card py-4 px-6 relative">
				<div className="absolute space-x-4 right-8 flex items-center"></div>
				<Breadcrumb
					items={[
						{ title: <Link to="/app/tools">All tools</Link> },
						{ title: 'Create new tool' },
						{
							title: <span className="capitalize">{searchParams.get('step')}</span>,
						},
					]}
				/>
				<Link to="/app/tools" className="flex items-center space-x-2 my-2 mt-4">
					<ArrowLeftOutlined className="text-[#000]" />
					<span className="text-xl text-[#000]">New tool</span>
				</Link>
				<Tabs
					activeKey={searchParams.get('step') || 'info'}
					onChange={(key) => {
						searchParams.set('step', key)
						navigate({ pathname: '', search: searchParams.toString() })
					}}
					items={[
						{ key: 'info', label: `General info` },
						{ key: 'prompt', label: `Prompt editor` },
						{ key: 'version', label: `Publish & version` },
					]}
				/>
			</div>
			<div className="flex space-x-4">
				<div className="flex-1">
					<AddToolForm onFormChange={_onFormChange} />
				</div>
				<div className="w-[358px]">
					<ViewToolCard tool={currentTool} />
				</div>
			</div>
		</div>
	)
}

export default CreateToolPage
