import { DynamicModuleLoader } from 'redux-dynamic-modules'
import React from 'react'

import toolsReducer, { startup } from 'modules/tools/reducers/toolsReducer'

import toolsSaga from 'modules/tools/sagas/toolsSaga'

import ToolsPage from 'modules/tools/components/ToolsPage'

const moduleToLoad = {
	id: 'tools',
	reducerMap: { tools: toolsReducer },
	sagas: [toolsSaga],
	initialActions: [startup()],
}

export default function DynamicToolsPage(props) {
	return (
		<DynamicModuleLoader modules={[moduleToLoad]}>
			<ToolsPage {...props} />
		</DynamicModuleLoader>
	)
}
