import { Divider, Skeleton, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import React from 'react'

import Page from 'shared/components/page/Page'

import { useGetGlobalStatsQuery } from 'shared/api/monitoringsApi'

const MonitorPage = () => {
	const { data: globalStats, isLoading, isError } = useGetGlobalStatsQuery()

	return (
		<Page id="monitor" className="p-4">
			<div className="flex items-center justify-between space-x-4 mb-4">
				<div className="card px-4 pt-6">
					<div className="flex items-center justify-between mb-1">
						<Typography.Text type="secondary">All users</Typography.Text>
						<InfoCircleOutlined />
					</div>
					<Typography.Title level={2}>
						{!isLoading && !isError ? globalStats?.nb_users : <Skeleton.Button size="small" />}
					</Typography.Title>
					<Divider />
				</div>
				<div className="card px-4 pt-6">
					<div className="flex items-center justify-between mb-1">
						<Typography.Text type="secondary">Daily active users</Typography.Text>
						<InfoCircleOutlined />
					</div>
					<Typography.Title level={2}>
						{!isLoading && !isError ? globalStats?.nb_active_users : <Skeleton.Button size="small" />}
					</Typography.Title>
					<Divider />
				</div>
				<div className="card px-4 pt-6">
					<div className="flex items-center justify-between mb-1">
						<Typography.Text type="secondary">All tools</Typography.Text> <InfoCircleOutlined />
					</div>
					<Typography.Title level={2}>
						{!isLoading && !isError ? globalStats?.nb_tools : <Skeleton.Button size="small" />}
					</Typography.Title>
					<Divider />
				</div>
			</div>
			<div></div>
		</Page>
	)
}

export default MonitorPage
