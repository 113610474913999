import { Button as AntdButton } from 'antd'
import { Box, Button, ButtonGroup } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

import { AnimatedList, AnimatedListItem } from 'modules/home/components/list'

const activeStyle = {
	background: '#FFFFFF',
	boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.04)',
	borderRadius: '5px',
	border: 'none',
}

const inactiveStyle = { background: '#F7F7F8', border: 'none' }

const prices = [
	{
		price: 18,
		title: 'Team',
		description: 'Individuals exploring generative AI. Up to 5 users.',
		details: [
			{ title: 'Centralized Knowledge Management', isChecked: true },
			{ title: 'Up to 100 customisable prompt tools', isChecked: true },
			{ title: 'Up to 100GB storage', isChecked: true },
			{ title: 'Advanced AI search engine.', isChecked: true },
			{ title: 'ChatGPT integration', isChecked: true },
			{ title: 'Admin management interface', isChecked: true },
			{ title: 'Email support', isChecked: true },
		],
	},
	{
		price: 'Custom Pricing',
		title: 'Entreprise',
		description: 'Organizations embedding generative AI into business processes.',
		details: [
			{ title: 'Batch knowledge base integration', isChecked: true },
			{ title: 'Unlimited customisable prompt tools', isChecked: true },
			{ title: 'Unlimited storage', isChecked: true },
			{ title: 'Sensitive information monitoring', isChecked: true },
			{ title: 'Multiple self-hosted LLM models support', isChecked: true },
			{ title: 'On-premises deployment', isChecked: true },
			{ title: 'Custom Language Models', isChecked: true },
			{ title: 'Kubernetes Integration', isChecked: true },
			{ title: '24/7 online support', isChecked: true },
			{ title: 'Dedicated account manager', isChecked: true },
		],
	},
]

const PricingModule = () => {
	const navigate = useNavigate()

	const [currentType, setCurrentType] = useState('month')

	return (
		<div className="bg-white text-center pt-24 -mb-8" id="pricing">
			<div className="max-w-7xl layout">
				<div className="mb-12">
					<div className="text-[#5A47CD] text-base font-medium">Pricing</div>
					<div className="text-3xl text-[#151B28] max-w-[800px] m-auto font-medium mt-2">
						<span className="text-3xl text-[#4AC7FA]">Simple, Transparent </span>Pricing for Businesses of All Sizes
					</div>
				</div>
				<div className="space-y-6">
					<ButtonGroup
						value={currentType}
						gap="6px"
						padding="4px"
						background="#F7F7F8"
						border="1px solid #EAEBF0"
						borderRadius="5px"
					>
						<Button
							size="md"
							className="sm:px-16 px-8 py-2"
							onClick={() => setCurrentType('month')}
							style={currentType === 'month' ? activeStyle : inactiveStyle}
						>
							Monthly
						</Button>
						<Button
							size="md"
							className="sm:px-16 px-8 py-2"
							onClick={() => setCurrentType('year')}
							style={currentType === 'year' ? activeStyle : inactiveStyle}
						>
							Yearly
							<span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-2 ml-3 text-xs font-medium ring-1 ring-inset ring-yellow-700/10">
								10% off
							</span>
						</Button>
					</ButtonGroup>
					<AnimatedList>
						<div className="grid-cols-1 lg:grid-cols-2 grid gap-8">
							{prices.map((item, i) => (
								<AnimatedListItem key={i}>
									<Box
										minWidth="316px"
										p="32px"
										border="1px solid #EAEBF0"
										bg="white"
										className={item.title === 'Entreprise' && 'border-deep-purple-accent-400'}
										boxShadow="0px 1px 2px rgba(16, 24, 40, 0.04)"
										borderRadius="5px"
										textAlign="left"
									>
										{typeof item.price === 'number' ? (
											<Box className="relative" mb="16px">
												<span className="text-4xl font-medium text-[#272D37]">
													€{currentType === 'year' ? item.price * 12 * 0.9 : item.price}
												</span>
												{currentType === 'year' && (
													<span className="text-xl text-[#5F6D7E] line-through pl-2">{item.price * 12}</span>
												)}
												<span className="text-xl pl-2 text-[#5F6D7E]">
													per user/{currentType === 'year' ? 'year' : 'month'}
												</span>
												<span className="absolute top-0 right-0 inline-flex items-center rounded-md bg-stale-50 px-2 py-2 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
													Basic Plan
												</span>
											</Box>
										) : (
											<Box className="relative" mb="16px">
												<span className="text-4xl font-medium text-[#272D37]">Custom Pricing</span>
												<span className=" absolute top-0 right-0 inline-flex items-center rounded-md bg-indigo-50 px-2 py-2 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
													Advanced Plan
												</span>
											</Box>
										)}
										<div className="space-y-1 mb-6">
											<div className="text-xl text-[#272D37] font-medium">{item.title}</div>
											<div className="text-sm text-[#5A47CD]">{item.description}</div>
											<div className="text-base text-[#00000073]">{item.subDescription}</div>
										</div>
										{item.title === 'Entreprise' ? (
											<AntdButton onClick={() => navigate('/auth/login')} type="primary" size="large" block>
												Contact for custom pricing
											</AntdButton>
										) : (
											<AntdButton onClick={() => navigate('/auth/login')} type="primary" size="large" block>
												Start free trial
											</AntdButton>
										)}

										<Box borderBottom="1px solid #EAEBF0" margin="24px 0px" />
										<div className="space-y-3">
											{item.title === 'Entreprise' && (
												<span className="text-xl text-[#272D37] font-medium">Everything in Team, plus:</span>
											)}
											{item.details.map(({ title, isChecked }, i) => (
												<div key={i} className="flex items-center space-x-3">
													{isChecked ? (
														<svg
															width="21"
															height="21"
															viewBox="0 0 21 21"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<rect x="0.0585938" y="0.0681152" width="20" height="20" rx="10" fill="#5A47CD" />
															<path
																d="M14.0586 8.06812L8.91215 13.2146C8.71689 13.4098 8.4003 13.4098 8.20504 13.2146L6.05859 11.0681"
																stroke="white"
																strokeWidth="1.67"
																strokeLinecap="round"
															/>
														</svg>
													) : (
														<svg
															width="21"
															height="21"
															viewBox="0 0 21 21"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<rect x="1.05859" y="1.06812" width="18" height="18" rx="9" fill="white" />
															<rect
																x="1.05859"
																y="1.06812"
																width="18"
																height="18"
																rx="9"
																stroke="#DAE0E6"
																strokeWidth="2"
															/>
														</svg>
													)}
													<span className="text-[#272D37]">{title}</span>
												</div>
											))}
										</div>
									</Box>
								</AnimatedListItem>
							))}
						</div>
					</AnimatedList>
				</div>
			</div>
		</div>
	)
}

export default PricingModule
