import { apiGPT } from './index'

export const toolApi = apiGPT.injectEndpoints({
	endpoints: (build) => ({
		getTools: build.query({
			providesTags: () => ['tool'],
			query: (params) => {
				return { url: `tools/templates/`, params }
			},
		}),

		getRecentTools: build.query({
			providesTags: () => ['tool'],
			query: () => `tools/templates/?order_by=last_used&order=desc&offset=0&limit=5`,
		}),

		getToolsCategories: build.query({
			providesTags: () => ['tool'],
			query: (params) => {
				return { url: `tools/categories/`, params }
			},
		}),

		getTool: build.query({
			providesTags: () => ['tool'],
			query: (id) => `tools/templates/${id}`,
		}),

		addTool: build.mutation({
			invalidatesTags: () => ['tool'],
			query: (body) => {
				return { url: `tools/templates/`, method: 'POST', body }
			},
		}),

		putTool: build.mutation({
			invalidatesTags: () => ['tool'],
			query: ({ body, id }) => {
				return { url: `tools/templates/${id}`, method: 'PUT', body }
			},
		}),

		deleteTool: build.mutation({
			invalidatesTags: () => ['tool'],
			query: (id) => ({ url: `tools/templates/${id}`, method: 'DELETE' }),
		}),
	}),
})

export const {
	useGetToolsQuery,
	useGetRecentToolsQuery,
	useGetToolsCategoriesQuery,
	useGetToolQuery,
	usePutToolMutation,
	useAddToolMutation,
	useDeleteToolMutation,
} = toolApi
