import { fork, put, take, takeLatest } from 'redux-saga/effects'

import {
	getFromStorage,
	getFromStorageSuccess,
	removeToStorage,
	removeToStorageSuccess,
	setToStorage,
	setToStorageSuccess,
} from 'shared/actions/sharedActions'
import {
	logout,
	logoutFailure,
	logoutSuccess,
	setToken,
	setTokenSuccess,
	startup,
	startupFailure,
	startupSuccess,
} from 'modules/root/reducers/rootReducer'
import { selectCurrentUser } from 'modules/root/selectors/rootSelectors'
import { useGetUserQuery } from 'shared/api/userApi'

function* watchStartup() {
	yield takeLatest(startup.type, startupSaga)
}

function* startupSaga() {
	try {
		yield put(getFromStorage({ id: 'root', resourceKey: 'access_token' }))
		const {
			payload: { resourceData },
		} = yield take(({ type, payload }) => type === getFromStorageSuccess.type && payload.id === 'root')

		if (resourceData) {
			yield put(setToken({ token: resourceData }))
			yield take(setTokenSuccess.type)
			yield put(
				selectCurrentUser(() => {
					const { data: user } = useGetUserQuery()
					return user
				})
			)
		}
		yield put(startupSuccess())
	} catch (err) {
		yield put(startupFailure(err))
	}
}

export function* watchSetToken() {
	yield takeLatest(setToken.type, setTokenSaga)
}

export function* setTokenSaga({ payload }) {
	yield put(setToStorage({ id: 'access_token', resourceKey: 'access_token', resourceValue: payload.token }))
	yield take(setToStorageSuccess.type)
	yield put(setTokenSuccess({ token: payload.token }))
}

export function* watchLogout() {
	yield takeLatest(logout.type, logoutSaga)
}

export function* logoutSaga() {
	try {
		yield put(removeToStorage({ resourceKey: 'access_token', resourceId: 'root' }))
		yield take(removeToStorageSuccess.type)

		yield put(logoutSuccess())
	} catch (err) {
		yield put(logoutFailure(err))
	}
}

export default function* rootSaga() {
	yield fork(watchStartup)
	yield fork(watchSetToken)
	yield fork(watchLogout)
}
