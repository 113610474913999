import { Button } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

import features from 'modules/home/features'
import workflow from 'modules/home/workflow'

import bg from 'assets/images/bg.jpg'
import workflow1 from 'assets/images/workflow1.png'
import workflow2 from 'assets/images/workflow2.png'
import workflow3 from 'assets/images/workflow3.png'
import workflow4 from 'assets/images/workflow4.png'

import { AnimatedList, AnimatedListItem } from 'modules/home/components/list'
import PricingModule from 'modules/home/components/PricingModule'

import * as Styled from 'modules/home/components/__styles__/HomePage.styles'

const HomePage = () => {
	return (
		<Styled.Root>
			<div className="relative max-w-7xl layout h-[570px] -mt-20 pt-20" id="home">
				<img src={bg} alt="nubulas" className="absolute w-6/12 right-0" />
				<div className="sm:py-16 py-8 absolute">
					<div className="max-w-[538px]">
						<div className="text-5xl font-medium text-[#151B28]">Nebulas.ai</div>
						<Styled.ColorText>Data-Secure Custom Language Models, Prompt Tools & AI Knowledge Base</Styled.ColorText>
						<div className="text-base text-[#000000] mt-6 mb-8">
							Easily integrate GPT technology and centralize knowledge management with our on-premises deployment
							platform, simplifying workflows and enhancing decision-making.
						</div>
						<Link to="/auth/login">
							<Button type="primary" size="large">
								Get Started
							</Button>
						</Link>
					</div>
				</div>
			</div>
			<Styled.Container className="max-w-7xl layout">
				<div className="text-center pt-24 -mb-8" id="features">
					<div className="max-w-7xl layout">
						<div className="mb-12">
							<div className="text-[#5A47CD] text-base font-medium">Unleash Your Potential</div>
							<div className="text-3xl text-[#151B28] max-w-[800px] m-auto font-medium mt-2">
								<span className="text-3xl text-[#4AC7FA]">Nebulas.ai</span> for Business Success and Personal Growth
							</div>
						</div>
						<AnimatedList>
							<div className="sm:grid-cols-2 grid-cols-1 grid gap-4">
								{features.map(({ title, description, icon }, i) => (
									<AnimatedListItem key={i}>
										<Styled.Card className="space-y-2">
											<div>{icon}</div>
											<div className="text-xl text-[#000000d9]">{title}</div>
											<div className="text-sm text-[#000000d9]">{description}</div>
										</Styled.Card>
									</AnimatedListItem>
								))}
							</div>
						</AnimatedList>
					</div>
				</div>
				<div className="text-center pt-24 -mb-8" id="workflow">
					<div className="max-w-7xl layout">
						<div className="mb-12">
							<div className="text-[#5A47CD] text-base font-medium">Analyse, Generate & Collect</div>
							<div className="text-3xl text-[#151B28] max-w-[800px] m-auto font-medium mt-2">
								A GPT Fully Enabled
								<span className="text-3xl text-[#4AC7FA]"> Workflow</span>
							</div>
						</div>
						<AnimatedList>
							<div className="lg:grid-cols-4 md:grid-cols-2 grid-cols-1 grid gap-4">
								{workflow.map(({ title, icon }, i) => (
									<AnimatedListItem key={i}>
										<Styled.WorkFlowCard>
											<Styled.Badge>{i + 1}</Styled.Badge>
											<div className="absolute text-sm text-[#000000d9] z-[10]">{title}</div>
											<div className="absolute top-[2px] right-[16px] z-[1]">{icon}</div>
										</Styled.WorkFlowCard>
									</AnimatedListItem>
								))}
							</div>
						</AnimatedList>
						<div className="space-y-4">
							<div className="flex-row sm:flex justify-between items-center">
								<div className="max-w-[530px]">
									<Styled.Badge>1</Styled.Badge>
									<div className="text-2xl font-medium text-[#151B28] mt-2 text-left">
										Generate content effortlessly with tailored custom prompt tools for your knowledge base
									</div>
								</div>
								<div>
									<img src={workflow1} alt="workflow" className="max-w-[360px]" />
								</div>
							</div>
							<div className="flex-row sm:flex justify-between items-center">
								<div>
									<img src={workflow2} alt="workflow" className="max-w-[360px]" />
								</div>
								<div className="max-w-[530px]">
									<Styled.Badge>2</Styled.Badge>
									<div className="text-2xl font-medium text-[#151B28] mt-2 text-left">
										Save the generated contents to your knowledge base, empowering the custom language model
									</div>
								</div>
							</div>
							<div className="flex-row sm:flex justify-between items-center">
								<div className="max-w-[530px]">
									<Styled.Badge>3</Styled.Badge>
									<div className="text-2xl font-medium text-[#151B28] mt-2 text-left">
										Enable the refinement of your private large language models with incorporating content, remarks, and
										users feedback
									</div>
								</div>
								<div>
									<img src={workflow3} alt="workflow" className="max-w-[360px]" />
								</div>
							</div>
							<div className="flex-row sm:flex justify-between items-center">
								<div>
									<img src={workflow4} alt="workflow" className="max-w-[360px]" />
								</div>
								<div className="max-w-[530px]">
									<Styled.Badge>4</Styled.Badge>
									<div className="text-2xl font-medium text-[#151B28] mt-2 text-left">
										Supercharge daily usage with an advanced prompt tool powered by a tailored language model
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<PricingModule />
				<div className="text-center pt-24 -mb-8" id="contact">
					<div className="max-w-7xl layout mb-8">
						<div className="text-[#151B28] text-3xl">Couldn't find the ideal plan you were looking for?</div>
						<div className="text-[#252D3C] mt-2 mb-6">
							Contact our sales to get customized service plan and tailored features
						</div>
						<Button size="large">Contact Our Sales</Button>
					</div>
				</div>
			</Styled.Container>
		</Styled.Root>
	)
}

export default HomePage
