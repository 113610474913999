import { apiKB } from './index'

export const categoryApi = apiKB.injectEndpoints({
	endpoints: (build) => ({
		getStats: build.query({
			providesTags: () => ['stats'],
			query: () => `stats/`,
		}),

		getCategories: build.query({
			providesTags: () => ['Category'],
			query: () => `categories/`,
		}),

		addCategory: build.mutation({
			invalidatesTags: () => ['Category'],
			query: (body) => {
				return { url: `categories/`, method: 'POST', body }
			},
		}),

		deleteCategory: build.mutation({
			invalidatesTags: () => ['Category'],
			query: (category_id) => ({ url: `categories/${category_id}`, method: 'DELETE' }),
		}),
	}),
})

export const { useGetStatsQuery, useGetCategoriesQuery, useAddCategoryMutation, useDeleteCategoryMutation } =
	categoryApi
