import { apiGPT } from './index'

export const dashboardApi = apiGPT.injectEndpoints({
	endpoints: (build) => ({
		getDashboardTools: build.query({
			query: ({ orderby, order, offset, limit }) =>
				`tools/templates/?orderby=${orderby}&order=${order}&offset=${offset}&limit=${limit}`,
		}),

		getDashboardDocuments: build.query({
			query: ({ filter_field, filter_value, order, offset, limit }) =>
				`contents/?filter_field=${filter_field}&filter_value=${filter_value}&order=${order}&offset=${offset}&limit=${limit}`,
		}),
	}),
})

export const { useGetDashboardToolsQuery, useGetDashboardDocumentsQuery } = dashboardApi
