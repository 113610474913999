import { DynamicModuleLoader } from 'redux-dynamic-modules'
import React from 'react'

import knowledgeReducer, { startup } from 'modules/knowledge/reducers/knowledgeReducer'

import knowledgeSaga from 'modules/knowledge/sagas/knowledgeSaga'

import KnowledgePage from 'modules/knowledge/components/KnowledgePage'

const moduleToLoad = {
	id: 'knowledge',
	reducerMap: { knowledge: knowledgeReducer },
	sagas: [knowledgeSaga],
	initialActions: [startup()],
}

export default function DynamicKnowledgePage(props) {
	return (
		<DynamicModuleLoader modules={[moduleToLoad]}>
			<KnowledgePage {...props} />
		</DynamicModuleLoader>
	)
}
