import { Button, Form, Input } from 'antd'
import { GoogleLogin } from '@react-oauth/google'
import { Link, useNavigate } from 'react-router-dom'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { register } from 'modules/auth/reducers/authReducer'
import { selectIsLoading } from 'modules/auth/selectors/authSelectors'
import { useDispatch, useSelector } from 'react-redux'
import Page from 'shared/components/page/Page'
import React from 'react'
import Typography from 'antd/es/typography/Typography'

const RegisterPage = () => {
	const isLoading = useSelector(selectIsLoading)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const onFinish = ({ email, firstname, lastname, password }) =>
		dispatch(register({ email, firstname, lastname, password, navigate }))

	return (
		<Page id="register">
			<div className="grid sm:grid-cols-2 grid-cols-1 divide-solid divide-x divide-[#cccccc]">
				<div className="sm:px-16 px-10 pt-8 mt-8 max-w-[511px]">
					<div className="font-bold flex">
						<span className="text-[#5A47CD] text-5xl">Nebulas.ai</span>
					</div>
					<div className="mt-2 mb-16 text-[32px] font-normal leading-[48px]">Empowering Tomorrow, Today with AI.</div>
				</div>
				<Form onFinish={onFinish} layout="vertical" className="px-16 text-left max-w-[511px]">
					<div className="text-[#5A47CD] text-xl font-medium">Sign Up</div>
					<div className="text-sm mb-4">Sign up with email</div>
					<Form.Item name="email" rules={[{ required: true }]}>
						<Input prefix={<UserOutlined />} placeholder="Email" size="large" />
					</Form.Item>
					<Form.Item
						name="firstname"
						rules={[
							{
								required: true,
							},
						]}
						style={{
							display: 'inline-block',
							width: 'calc(50% - 8px)',
						}}
					>
						<Input placeholder="First Name" size="large" />
					</Form.Item>
					<Form.Item
						name="lastname"
						rules={[
							{
								required: true,
							},
						]}
						style={{
							display: 'inline-block',
							width: 'calc(50% - 8px)',
							marginLeft: '15px',
						}}
					>
						<Input placeholder="Last Name" size="large" />
					</Form.Item>
					<Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
						<Input
							placeholder="Password"
							size="large"
							type="password"
							autoComplete='"current-password'
							prefix={<LockOutlined />}
						/>
					</Form.Item>
					<Form.Item>
						<Button disabled={isLoading} loading={isLoading} type="primary" htmlType="submit" size="large">
							Next
						</Button>
					</Form.Item>
					<Form.Item label="Sign up with Google">
						<GoogleLogin
							text="Continue with Google"
							onSuccess={(credentialResponse) => {
								// console.log(credentialResponse)
							}}
							onError={() => {
								// console.log('Login Failed')
							}}
						/>
					</Form.Item>
					<Typography.Text>
						Already have an account ? <Link to="/auth/login/">Sign In</Link>
					</Typography.Text>
				</Form>
			</div>
		</Page>
	)
}

export default RegisterPage
