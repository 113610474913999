import { AiOutlineClose } from 'react-icons/ai'
import { Layout as AppLayout, Badge, Menu } from 'antd'
import { AppstoreOutlined, ArrowRightOutlined, BarChartOutlined, DatabaseOutlined } from '@ant-design/icons'
import { Outlet, useNavigate } from 'react-router-dom'
import { presetPalettes } from '@ant-design/colors'
import React, { useState } from 'react'

import foldIcon from 'assets/images/fold.svg'
import unfoldIcon from 'assets/images/unfold.svg'

import ToolIcon from 'modules/tools/components/icon/ToolIcon'

import AppHeader from 'shared/components/layout/AppHeader'

import { useGetRecentToolsQuery, useGetToolsQuery } from 'shared/api/toolApi'
import { useGetUserQuery } from 'shared/api/userApi'

import * as Styled from 'shared/components/layout/__styles__/Layout.styles'

const Layout = () => {
	const navigate = useNavigate()

	const [collapsed, setCollapsed] = useState(false)
	const [currentKey, setCurrentKey] = useState('1')
	const [showPanel, setShowPanel] = useState(null)

	const { data: allTools } = useGetToolsQuery()
	const { data: user } = useGetUserQuery()
	const { data: toolsData } = useGetRecentToolsQuery()

	const navItems = [
		{
			icon: <BarChartOutlined />,
			label: (
				<div className="flex items-center justify-between">
					Dashboard
					<ArrowRightOutlined />
				</div>
			),
			href: '/app',
			key: '1',
		},
		{
			icon: <DatabaseOutlined />,
			label: (
				<div className="flex items-center justify-between">
					Knowledge base
					<ArrowRightOutlined />
				</div>
			),
			href: '/app/knowledge',
			key: '2',
		},
		{
			icon: <AppstoreOutlined />,
			label: (
				<div className="flex items-center justify-between">
					Prompt tools
					{allTools && <Badge count={allTools.length} showZero color="#5A47CD" />}
					<ArrowRightOutlined />
				</div>
			),
			href: '/app/tools',
			key: '3',
		},
		{
			icon: (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" width="18" height="16" fill="none">
					<path
						d="M0.666748 5.49478C0.666748 2.73624 2.8972 0.5 5.6673 0.5H12.3328C15.0946 0.5 17.3334 2.74565 17.3334 5.49478V15.5H5.6673C2.90557 15.5 0.666748 13.2543 0.666748 10.5052V5.49478ZM15.6667 13.8333V5.49478C15.6667 3.66283 14.1708 2.16667 12.3328 2.16667H5.6673C3.82056 2.16667 2.33341 3.65382 2.33341 5.49478V10.5052C2.33341 12.3372 3.82935 13.8333 5.6673 13.8333H15.6667ZM10.6667 7.16667H12.3334V8.83333H10.6667V7.16667ZM5.66675 7.16667H7.33341V8.83333H5.66675V7.16667Z"
						fill="currentColor"
						fillOpacity="0.9"
					/>
				</svg>
			),
			label: (
				<div className="flex items-center justify-between">
					Chatbot
					<ArrowRightOutlined />
				</div>
			),
			href: '/app/chat',
			key: '4',
		},
		...(user?.is_superuser
			? [
					{
						icon: (
							<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.34062 18.375V13.4531H10.6531V15.2688H18.375V16.5813H10.6531V18.375H9.34062ZM2.625 16.5813V15.2688H8.02812V16.5813H2.625ZM6.71562 12.95V11.1562H2.625V9.84375H6.71562V8.00625H8.02812V12.95H6.71562ZM9.34062 11.1562V9.84375H18.375V11.1562H9.34062ZM12.9719 7.54688V2.625H14.2844V4.41875H18.375V5.73125H14.2844V7.54688H12.9719ZM2.625 5.73125V4.41875H11.6594V5.73125H2.625Z"
									fill="currentColor"
									fillOpacity="0.9"
								/>
							</svg>
						),
						label: (
							<div className="flex items-center justify-between">
								Monitor
								<ArrowRightOutlined />
							</div>
						),
						href: '/app/monitor',
						key: '5',
					},
			  ]
			: []),
		{
			label: 'Recent',
			key: 'sub1',
			children: toolsData?.map((item, i) => ({
				icon: <ToolIcon type={item.logo_id} color={Object.values(presetPalettes)[i][6]} />,
				label: item.title,
				key: `/app/tools/render/${item.id}`,
				href: `/app/tools/render/${item.id}`,
			})),
		},
	]

	const _onMenuClick = ({ key }) => {
		const nav = navItems.find((item) => item.key === key || item?.children?.map((i) => i.key).includes(key))
		navigate(nav.href || nav.children.find((item) => item.key === key).href)
		setCurrentKey(nav.href ? nav.key : nav.children.find((item) => item.key === key).key)
	}

	return (
		<Styled.Root className="font-sans">
			<AppHeader setShowPanel={setShowPanel} />
			<AppLayout hasSider>
				<Styled.Sider
					width="300px"
					theme="light"
					collapsible
					collapsed={collapsed}
					onCollapse={(value) => setCollapsed(value)}
					trigger={
						<div
							style={{
								width: 37,
								height: 37,
								backgroundColor: 'rgba(0, 0, 0, 0.06)',
								borderRadius: 8,
								padding: 8,
								margin: '5px auto auto 20px',
							}}
						>
							{collapsed ? (
								<img src={foldIcon} alt="collapse" width={21} height={21} />
							) : (
								<img src={unfoldIcon} alt="uncolapse" width={21} height={21} />
							)}
						</div>
					}
				>
					<Menu
						theme="light"
						onClick={_onMenuClick}
						mode="inline"
						selectedKeys={[currentKey]}
						defaultOpenKeys={['sub1']}
						items={navItems}
					/>
				</Styled.Sider>
				<Styled.AppContent style={{ display: 'flex', position: 'relative' }}>
					<div style={{ position: 'relative', width: '100%' }}>
						<Outlet />
					</div>

					{showPanel && (
						<Styled.Panel>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ fontSize: 20, fontWeight: 500 }}> {showPanel.title}</div>
								<div>
									<AiOutlineClose style={{ cursor: 'pointer' }} size={24} onClick={() => setShowPanel(false)} />
								</div>
							</div>
							<div>{showPanel.body}</div>
						</Styled.Panel>
					)}
				</Styled.AppContent>
			</AppLayout>
		</Styled.Root>
	)
}

export default Layout
