import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Skeleton, Switch, Tabs } from 'antd'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import React, { useState } from 'react'

import AddToolForm from 'modules/tools/components/form/AddToolForm'
import ViewToolCard from 'modules/tools/components/card/ViewToolCard'

import { useGetToolQuery } from 'shared/api/toolApi'

const ToolDetailsPage = () => {
	const [currentTool, setCurrentTool] = useState()

	const _onFormChange = (values) => setCurrentTool(values)

	const params = useParams()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const { data: toolData, isFetching: isGetToolFetching } = useGetToolQuery(params.id)

	const isReady = !isGetToolFetching && toolData?.id

	return (
		<div className="space-y-4">
			<div className="card py-4 px-6 relative">
				<div className="absolute space-x-4 right-8 flex items-center">
					<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" defaultChecked />
					{/*<Button className="flex items-center">*/}
					{/*	<ShareAltOutlined />*/}
					{/*	Send a request*/}
					{/*</Button>*/}
				</div>
				<Breadcrumb
					items={[
						{ title: <Link to="/app/tools">All tools</Link> },
						{ title: toolData?.title },
						{
							title: <span className="capitalize">{searchParams.get('step')}</span>,
						},
					]}
				/>
				<Link to="/app/tools" className="flex items-center space-x-2 my-2 mt-4">
					<ArrowLeftOutlined className="text-[#000]" />
					<span className="text-xl text-[#000]">
						{isReady ? toolData?.title : <Skeleton.Button active size="small" />}
					</span>
				</Link>
				<Tabs
					activeKey={searchParams.get('step') || 'info'}
					onChange={(key) => {
						searchParams.set('step', key)
						navigate({ pathname: '', search: searchParams.toString() })
					}}
					items={[
						{ key: 'info', label: `General info` },
						{ key: 'prompt', label: `Prompt editor` },
						{ key: 'version', label: `Publish & version` },
					]}
				/>
			</div>
			<div className="flex space-x-4">
				<div className="flex-1">
					<AddToolForm tool={toolData} onFormChange={_onFormChange} />
				</div>
				<div className="w-[358px]">
					<ViewToolCard tool={currentTool || toolData} />
				</div>
			</div>
		</div>
	)
}

export default ToolDetailsPage
