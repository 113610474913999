import { Input, Skeleton, Tag } from 'antd'
import {
	RiArrowRightLine,
	RiCommunityLine,
	RiDatabase2Line,
	RiLock2Line,
	RiSearchLine,
	RiTeamLine,
} from 'react-icons/ri'
import PropTypes from 'prop-types'
import React from 'react'

import { useGetCategoriesQuery, useGetStatsQuery } from 'shared/api/categoryApi'

import AddKnowledgeFormModal from 'modules/knowledge/components/modal/AddKnowledgeFormModal'

import dataScience from 'assets/images/knowledge/data-science.png'

import { Link, useNavigate } from 'react-router-dom'

const KnowledgeListPage = () => {
	const navigate = useNavigate()

	const { data: categoriesData, isFetching: isGetCategoriesFetching } = useGetCategoriesQuery()
	const { data: statsData, isFetching: isStatsDataFetching } = useGetStatsQuery()

	const _onSearch = (v) => navigate(`/app/knowledge/search?query=${v}`)

	return (
		<div className="space-y-4">
			<div className="card p-8 text-center space-y-8 relative">
				<div className="absolute space-x-4 right-8 flex items-center">
					<AddKnowledgeFormModal />
					{/*<Button className="flex items-center">*/}
					{/*	<ShareAltOutlined />*/}
					{/*	Send a request*/}
					{/*</Button>*/}
				</div>
				<RiDatabase2Line fontSize="64px" className="m-auto bg-[#F5F0FF] rounded-lg p-1" color="#5A47CD" />
				<div className="text-3xl flex items-center space-x-2 justify-center">
					{isStatsDataFetching ? (
						<Skeleton.Button />
					) : (
						<span className="text-3xl">{statsData?.stats_by_acl?.reduce((acc, item) => acc + item.count, 0)}</span>
					)}
					<span className="text-3xl">documents</span>
				</div>
				<Input.Search
					onSearch={_onSearch}
					size="large"
					className="max-w-[474px]"
					placeholder="Provide me a detailed description here..."
					enterButton={<RiSearchLine />}
				/>
				<div className="space-x-2">
					<Tag className="tag cursor-pointer" onClick={() => navigate(`/app/knowledge/search?query=Generative AI`)}>
						Generative AI
					</Tag>
					<Tag className="tag cursor-pointer" onClick={() => navigate(`/app/knowledge/search?query=Open AI`)}>
						Open AI
					</Tag>
					<Tag className="tag cursor-pointer" onClick={() => navigate(`/app/knowledge/search?query=Documents`)}>
						Documents
					</Tag>
					<Tag className="tag cursor-pointer" onClick={() => navigate(`/app/knowledge/search?query=Travel policy`)}>
						Travel policy
					</Tag>
					<Tag className="tag cursor-pointer" onClick={() => navigate(`/app/knowledge/search?query=IT`)}>
						IT
					</Tag>
				</div>
			</div>
			<div className="flex items-center justify-between space-x-4">
				<div className="card p-6 space-y-2">
					<RiLock2Line fontSize="36px" />
					<div className="font-base">My knowledge base</div>
					<Link to={`/app/knowledge/documents?access_level=personal`}>
						<div className="text-[#5A47CD] font-medium cursor-pointer flex items-center justify-between">
							<span>{statsData?.stats_by_acl?.find((item) => item.access_level === 'personal')?.count} documents</span>
							<RiArrowRightLine fontSize="20px" />
						</div>
					</Link>
				</div>
				<div className="card p-6 space-y-2">
					<RiTeamLine fontSize="36px" />
					<div className="font-base">Team knowledge base</div>
					<Link to={`/app/knowledge/documents?access_level=team`}>
						<div className="text-[#5A47CD] font-medium cursor-pointer flex items-center justify-between">
							<span>{statsData?.stats_by_acl?.find((item) => item.access_level === 'team')?.count} documents</span>
							<RiArrowRightLine fontSize="20px" />
						</div>
					</Link>
				</div>
				<div className="card p-6 space-y-2">
					<RiCommunityLine fontSize="36px" />
					<div className="font-base">Public knowledge base</div>
					<Link to={`/app/knowledge/documents?access_level=public`}>
						<div className="text-[#5A47CD] font-medium flex items-center justify-between">
							<span>{statsData?.stats_by_acl?.find((item) => item.access_level === 'public')?.count} documents</span>
							<RiArrowRightLine fontSize="20px" />
						</div>
					</Link>
				</div>
			</div>
			<div className="px-6 text-xl">All categories</div>
			<Skeleton active loading={isGetCategoriesFetching} className="p-6" />
			<div className="grid gap-4 grid-cols-4">
				{categoriesData?.map(({ category, description }, i) => {
					const currentStats = statsData?.stats_by_cat.find((item) => item.category === category)
					return (
						<div className="card flex flex-col" key={i}>
							<div className="bg-[#5a47cd08] py-6">
								<img className="h-[100px] mx-auto" src={dataScience} alt="data science" />
							</div>
							<div className="space-y-2 p-6 flex flex-col flex-1">
								<div className="font-base font-medium">{category}</div>
								<div className="font-base font-medium flex-1">{description}</div>
								<Link to={`/app/knowledge/documents?category=${category}`}>
									<div className="py-1 text-[#5A47CD] font-medium cursor-pointer flex items-center justify-between">
										<span>{currentStats?.count} documents</span>
										<RiArrowRightLine fontSize="20px" />
									</div>
								</Link>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default KnowledgeListPage

KnowledgeListPage.propTypes = {
	/** Content to display if there are no errors. */
	children: PropTypes.node,
}

KnowledgeListPage.defaultProps = {
	children: null,
}
