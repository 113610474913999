import { Button, Form, message } from 'antd'

import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect } from 'react'

import InfoFromItems from 'modules/tools/components/form/InfoFromItems'
import PromptFormItems from 'modules/tools/components/form/PromptFormItems'
import VersionFormItems from 'modules/tools/components/form/VersionFormItems'

import { useAddToolMutation, usePutToolMutation } from 'shared/api/toolApi'

const AddToolForm = ({ tool, onFormChange }) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [form] = Form.useForm()

	const [putTool, { isLoading: isPutToolLoading }] = usePutToolMutation()
	const [addTool, { isLoading: isAddToolLoading }] = useAddToolMutation()

	const _onFinish = async (values) => {
		if (tool?.id) {
			const res = await putTool({ body: values, id: tool.id })
			if (res.data) {
				message.success('Success edit tool!')
			} else {
				message.error('Failed to edit tool!')
			}
		} else {
			const res = await addTool(values)
			if (res.data) {
				message.success('Success add a tool!')
				navigate(`/app/tools/${res?.data?.id}`)
			} else {
				message.error('Failed to add a tool!')
			}
		}
	}

	useEffect(() => {
		tool && form.setFieldsValue(tool)
	}, [tool, form])

	const _onFormChange = (_, allValues) => {
		onFormChange(allValues)
	}

	return (
		<div className="card p-6">
			<Form form={form} initialValues={{ icon: 'MessageOutlined' }} onFinish={_onFinish} onValuesChange={_onFormChange}>
				<Form.Item shouldUpdate>
					{() => {
						switch (searchParams.get('step')) {
							case 'info':
								return <InfoFromItems form={form} />
							case 'prompt':
								return <PromptFormItems form={form} />
							case 'version':
								return <VersionFormItems />
							default:
								return null
						}
					}}
				</Form.Item>
				<Form.Item wrapperCol={{ span: 24 }}>
					<Button type="primary" htmlType="submit" className="w-full" disabled={isPutToolLoading || isAddToolLoading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default AddToolForm
