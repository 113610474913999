import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'

export const AnimatedListItem = ({ children, ...props }) => {
	const item = {
		hidden: { y: 20, opacity: 0 },
		visible: { y: 0, opacity: 1 },
		open: {
			y: 0,
			opacity: 1,
			transition: { y: { stiffness: 1000, velocity: -100 } },
		},
		closed: { y: 50, opacity: 0, transition: { y: { stiffness: 1000 } } },
	}

	return (
		<Box w="100%" variants={item} as={motion.li} {...props}>
			{children}
		</Box>
	)
}
