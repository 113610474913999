import { DynamicModuleLoader } from 'redux-dynamic-modules'
import React from 'react'

import chatReducer, { startup } from 'modules/chat/reducers/chatReducer'

import chatSaga from 'modules/chat/sagas/chatSaga'

import ChatPage from 'modules/chat/components/ChatPage'

const moduleToLoad = {
	id: 'chat',
	reducerMap: { chat: chatReducer },
	sagas: [chatSaga],
	initialActions: [startup()],
}

export default function DynamicchatPage(props) {
	return (
		<DynamicModuleLoader modules={[moduleToLoad]}>
			<ChatPage {...props} />
		</DynamicModuleLoader>
	)
}
