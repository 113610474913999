import styled from '@xstyled/styled-components'

// import home_bg from 'assets/images/bg.svg'

import Page from 'shared/components/page/Page'

export const Root = styled(Page)``

export const ColorText = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 52px;
	letter-spacing: -0.01em;
	background: linear-gradient(90deg, #4ac7fa 0%, #e649f5 100%), #151b28;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`

export const Card = styled.div`
	text-align: left;
	width: 100%;
	background: #fafafa;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	min-height: 252px;
	padding: 24px;
`

export const WorkFlowCard = styled.div`
	padding: 12px;
	text-align: left;
	min-width: 240px;
	min-height: 120px;
	background: #fafafa;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	position: relative;
`

export const Badge = styled.div`
	padding: 4px;
	width: 32px;
	height: 32px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #5a47cd;
	background: #f5f0ff;
	border-radius: 88px;
	margin-bottom: 8px;
`

export const Container = styled.div`
	position: relative;
	&::before {
		content: '';
		background-image: url('/static/bg.svg');
		background-size: contain;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		filter: blur(16px);
		opacity: 0.06;
	}
`
