import { DynamicModuleLoader } from 'redux-dynamic-modules'
import React from 'react'

import MonitorPage from 'modules/monitor/components/page/MonitorPage'

const moduleToLoad = {
	id: 'monitor',
}

export default function DynamicMonitorPage(props) {
	return (
		<DynamicModuleLoader modules={[moduleToLoad]}>
			<MonitorPage {...props} />
		</DynamicModuleLoader>
	)
}
