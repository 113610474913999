import { Layout } from 'antd'
import styled from '@xstyled/styled-components'

const { Header: _Header } = Layout

export const Root = styled(_Header)`
	background: white !important;
	z-index: 1;
	width: 100%;
	height: 56px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`
