import { VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React from 'react'

export const AnimatedList = ({ children, ...props }) => {
	const container = {
		open: { transition: { staggerChildren: 0.07, delayChildren: 0.2 } },
		closed: { transition: { staggerChildren: 0.05, staggerDirection: -1 } },
		hidden: { opacity: 1 },
		visible: {
			opacity: 1,
			transition: { delayChildren: 0.2, staggerChildren: 0.2 },
		},
	}

	return (
		<VStack
			variants={container}
			as={motion.ul}
			style={{ listStyle: 'none' }}
			initial={props?.initial || 'hidden'}
			animate={props?.animate || 'visible'}
			{...props}
		>
			{children}
		</VStack>
	)
}
