import { Button, Popconfirm, Tag, Typography } from 'antd'
import { CloseCircleOutlined, LockOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { useDeleteDocumentMutation } from 'shared/api/documentApi'
import { useGetUserQuery } from 'shared/api/userApi'

import chat from 'assets/images/chat.svg'
import chat_active from 'assets/images/chat_active.svg'
import pdf from 'assets/images/pdf.svg'

dayjs.extend(relativeTime)

const ContentCard = ({ data, onSelect, isSelect }) => {
	const { data: user } = useGetUserQuery()

	const [deleteDocument] = useDeleteDocumentMutation()

	const _onDownloadFile = async () => {
		const token = localStorage.getItem('access_token')
		await axios
			.get(`https://apis.nebulas.ai/knowledge-base/v1/files/${data.id}`, {
				responseType: 'blob',
				headers: { Authorization: `Bearer ${JSON.parse(token)}` },
			})
			.then((response) => {
				const url = URL.createObjectURL(response.data)
				const a = document.createElement('a')
				a.style = 'display:none'
				a.href = url
				a.download = data?.metadata?.filename
				document.body.appendChild(a)
				a.click()
				document.body.removeChild(a)
				URL.revokeObjectURL(url)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	return (
		<div className="card p-6 space-y-2 relative">
			{user?.id === data?.metadata?.user_id && (
				<div className="absolute right-[-14px] top-[-16px] inline-block" onClick={(e) => e.stopPropagation()}>
					<Popconfirm
						title="Delete the document"
						description="Are you sure to delete this document?"
						onConfirm={() => deleteDocument(data?.metadata?.document_id)}
						okText="Yes"
						cancelText="No"
					>
						<Button icon={<CloseCircleOutlined />} type="link" />
					</Popconfirm>
				</div>
			)}

			<div className="flex items-center justify-between">
				<Typography.Text type="secondary">{dayjs(data?.metadata?.created_at).fromNow()}</Typography.Text>
				<Button size="small" shape="circle" type="link" onClick={() => onSelect(data)}>
					{isSelect ? (
						<img src={chat_active} className="cursor-pointer" alt="chat" />
					) : (
						<img src={chat} className="cursor-pointer" alt="chat" />
					)}
				</Button>
			</div>
			<div
				className="no-underline hover:underline flex space-x-2 items-center cursor-pointer"
				onClick={_onDownloadFile}
			>
				<img src={pdf} alt="pdf" />
				<span className="text-xl">{data?.metadata?.filename}</span>
			</div>
			<div className="space-x-2">{data?.metadata?.category && <Tag color="blue">{data?.metadata?.category}</Tag>}</div>
			<div>
				<Typography.Text>{data.text}</Typography.Text>
			</div>
			{data?.metadata?.access_level === 'personal' && (
				<div className="text-[#00000073] flex datas-center space-x-1">
					<LockOutlined />
					<span>Personal</span>
				</div>
			)}
		</div>
	)
}

export default ContentCard

ContentCard.propTypes = {
	data: PropTypes.object,
	onSelect: PropTypes.func,
	isSelect: PropTypes.bool,
}

ContentCard.defaultProps = {
	data: null,
	onSelect: null,
	isSelect: false,
}
