import { Layout } from 'antd'
import styled from '@xstyled/styled-components'

const { Content: _Content, Sider: _Sider } = Layout

export const Root = styled(Layout)`
	min-height: 100vh !important;

	.ant-menu-item {
		height: 54px !important;
		margin: 8px !important;
		width: auto !important;
		display: flex !important;
	}

	.ant-menu-item-icon {
		svg {
			height: 18px !important;
			width: 18px !important;
		}
	}

	.ant-menu-submenu-title {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: rgba(0, 0, 0, 0.45);
	}
`

export const Content = styled(_Content)`
	margin-top: 84px;
	background-color: white;
	padding-bottom: 64px;
`

export const AppContent = styled(_Content)`
	background: #f3f4f9;
`

export const Sider = styled(_Sider)`
	.ant-layout-sider-trigger {
		justify-content: center;
		display: flex;
	}
`
export const Panel = styled.divBox`
	height: 100%;
	overflow: scroll;
	width: 374px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 15px 0px;
	border-left: 1px solid rgba(0, 0, 0, 0.06);
	background-color: white;
	padding: 24px;
	right: 0;
	position: absolute;
`
