import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { StyleProvider } from '@ant-design/cssinjs'
import { apiContext, apiStore } from 'shared/api'
import { createStore } from 'redux-dynamic-modules'
import { getSagaExtension } from 'redux-dynamic-modules-saga'
import React from 'react'

import rootModule from 'modules/root'

import sagas from 'shared/sagas'

import App from 'shared/components/app/App'

const sagaExtension = getSagaExtension()

export const store = createStore({
	extensions: [sagaExtension],
})

sagaExtension.middleware[0].run(sagas)

store.addModule(rootModule)

const Root = () => {
	return (
		<Provider context={apiContext} store={apiStore}>
			<Provider store={store}>
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#5A47CD',
							fontFamily: 'Poppins, Arial, sans-serif',
						},
					}}
				>
					<StyleProvider hashPriority="high">
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</StyleProvider>
				</ConfigProvider>
			</Provider>
		</Provider>
	)
}

export default Root
