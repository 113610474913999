import { Alert, Checkbox, Layout, Select } from 'antd'
import React from 'react'

import { useGetModelsQuery } from 'shared/api/chatApi'

const ConversationHeader = ({ isUseBaseData, currentModel, onSelect, onCheck }) => {
	const { data: modelData } = useGetModelsQuery()

	return (
		<>
			<Layout.Header>
				<div className="flex items-center justify-between">
					<div className="space-x-2">
						<span>Model</span>
						<Select
							value={currentModel}
							onSelect={onSelect}
							style={{ width: 220 }}
							options={modelData?.map((v) => ({ value: v.id, label: v.display_name }))}
						/>
					</div>
					<div>
						<Checkbox checked={isUseBaseData} onChange={onCheck}>
							Use Knowledge base data
						</Checkbox>
					</div>
				</div>
			</Layout.Header>
			{currentModel?.includes('gpt') && (
				<Alert
					className="rounded-none"
					message="Please select the self-hosted model when dealing with sensitive content"
					type="info"
					closable
				/>
			)}
		</>
	)
}

export default ConversationHeader
