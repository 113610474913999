import { Layout } from 'antd'
import styled from '@xstyled/styled-components'

export const Root = styled(Layout.Footer)`
	.ant-form-item-label > label {
		color: white;
	}
	.ant-btn {
		border: 1px solid #ffffff;
		filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.016));
		border-radius: 4px;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: rgba(255, 255, 255, 0.85);
		background-color: #5a47cd;
	}
`
