import { Badge, Button, Dropdown, Tabs } from 'antd'
import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import ToolGeneratorCards from 'modules/tools/components/ToolGeneratorCards'

import * as Styled from 'modules/tools/components/__styles__/ToolsPage.styles'
import { useGetToolsCategoriesQuery, useGetToolsQuery } from 'shared/api/toolApi'

const TabsItems = ({ category }) => {
	const params = category ? { category_id: category.id } : {}
	const { data: toolsByCategory } = useGetToolsQuery(params)

	return (
		<span>
			<span className="text-xl font-medium">
				{category ? (category.category === null ? 'Other' : category.category) : 'All'}
			</span>
			<Badge count={toolsByCategory?.length} showZero color="#5A47CD" />
		</span>
	)
}

const ToolsListPage = () => {
	const [orderby, setOrderBy] = useState('popularity')
	const [tabsItems, setTabsItems] = useState()
	const [currentCategoryId, setCurrentCategoryId] = useState()
	const { data: categories } = useGetToolsCategoriesQuery()

	useEffect(() => {
		async function fetchData() {
			let tabsItemsList = categories?.map((category, i) => {
				return {
					categoryName: category.category,
					categoryId: category.id,
					key: i + 2,
					label: <TabsItems category={category} />,
				}
			})
			tabsItemsList?.push({
				key: 1,
				label: <TabsItems />,
			})
			tabsItemsList?.sort((a, b) => a.key - b.key)
			setTabsItems(tabsItemsList)
		}
		fetchData()
	}, [categories])

	return (
		<Styled.Root className="space-y-4">
			<Tabs
				defaultActiveKey="1"
				items={tabsItems}
				onChange={(e) => {
					let toolsbycat = tabsItems.find((t) => t.key === e)
					if (toolsbycat.key !== 1 && toolsbycat.categoryId) setCurrentCategoryId(toolsbycat.categoryId)
					else setCurrentCategoryId(null)
				}}
			/>
			<div className="flex items-center justify-between">
				<Link to="/app/tools/new?step=info">
					<Button type="primary" className="flex items-center">
						<PlusOutlined />
						New tool
					</Button>
				</Link>
				<Dropdown
					menu={{
						items: [
							{
								key: '1',
								label: 'Popularity',
							},
							{
								key: '2',
								label: 'Date',
							},
						],
						onClick: (e) => {
							if (e.key === '1') setOrderBy('popularity')
							if (e.key === '2') setOrderBy('updated_at')
						},
					}}
					placement="bottom"
					arrow={{
						pointAtCenter: true,
					}}
				>
					<Button className="flex items-center">
						<FilterOutlined />
						{orderby === 'popularity' && 'Popularity'}
						{orderby === 'updated_at' && 'Date'}
						<DownOutlined />
					</Button>
				</Dropdown>
			</div>
			<ToolGeneratorCards orderby={orderby} categoryId={currentCategoryId} />
		</Styled.Root>
	)
}

export default ToolsListPage

ToolsListPage.propTypes = {}

ToolsListPage.defaultProps = {}
