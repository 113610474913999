import { Button, Divider, Modal, Select, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { isEmpty } from 'lodash'
import { presetPalettes } from '@ant-design/colors'
import { useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { useGetConversationsQuery, usePutConversationsMutation } from 'shared/api/chatApi'
import { useGetToolsQuery } from 'shared/api/toolApi'
import chat_active from 'assets/images/chat_active.svg'

import ToolIcon from 'modules/tools/components/icon/ToolIcon'

import pdf from 'assets/images/pdf.svg'

const AdvancedAnalystCard = ({ items, setSelectItem }) => {
	const [currentConversationId, setCurrentConversationId] = useState()

	const navigate = useNavigate()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [putConversations] = usePutConversationsMutation()
	const { data: toolsData } = useGetToolsQuery({
		order_by: 'popularity',
		order: 'desc',
	})

	const { data: chatData } = useGetConversationsQuery({ offset: 0, limit: 100 })
	const conversations = chatData?.filter((v) => v.prompt_tpl_id === '')

	if (isEmpty(items)) {
		return null
	}

	const _onForwardChatClick = async () => {
		await putConversations({
			body: {
				document_ids: items.map((i) => i.id),
			},
			id: currentConversationId,
		})
		navigate(`/app/chat?id=${currentConversationId}`)
	}

	const _onForwardToolClick = (tool) => () => {
		const ids = items.length > 1 ? items.map((i) => i.id).join('&ids=') : items[0].id
		navigate(`/app/tools/render/${tool?.id}?ids=${ids}`)
	}

	return (
		<div className="flex items-center space-x-2 m-auto w-max right-0 left-0 fixed card bottom-8 shadow-[0px 4px 25px 0px #00000040] p-2">
			<div className="flex items-center space-x-2">
				<img src={chat_active} alt="chat" />
				<span className="text-sm font-medium"></span>
			</div>
			{items.map((data, i) => (
				<div key={i} className="bg-[#F5F5F5] p-2">
					<div className="flex space-x-2 items-center">
						<img src={pdf} alt="pdf" />
						<span className="text-sm">{data?.metadata?.filename}</span>
					</div>
				</div>
			))}
			<div className="flex items-center space-x-2">
				<Button type="primary" onClick={onOpen} style={{ display: 'flex', alignItems: 'center' }}>
					Forward to <RightOutlined style={{ marginLeft: '10px' }} />
				</Button>
				<Button onClick={() => setSelectItem([])}>Dismiss</Button>
			</div>

			<Modal
				title="Forward to"
				open={isOpen}
				footer={null}
				onCancel={() => {
					onClose()
					setCurrentConversationId()
				}}
				width={600}
				styles={{ header: { backgroundColor: '#F3F4F9' } }}
				className="forward-modal"
			>
				<Typography.Title level={5}>Chatbot</Typography.Title>
				<div className="flex items-center space-x-2 px-4">
					<Select
						value={currentConversationId}
						className="w-full"
						onChange={setCurrentConversationId}
						options={conversations?.map((item) => ({ value: item.conversation_id, label: item.title }))}
					/>
					<Button disabled={!currentConversationId} type="primary" onClick={_onForwardChatClick}>
						Go
					</Button>
				</div>
				<Divider />
				<Typography.Title level={5}>Prompt tools</Typography.Title>
				<div className="px-4 grid grid-cols-4 gap-4">
					{toolsData?.map((tool, i) => (
						<div key={tool.id} className="max-w-[88px] cursor-pointer" onClick={_onForwardToolClick(tool)}>
							<div className="h-[88px] w-[88px] rounded-lg bg-white text-center pt-[26px]">
								<ToolIcon type={tool.logo_id} fontSize="36px" color={Object.values(presetPalettes)[i][5]} />
							</div>
							<div className="text-center text-xs bg-[#F3F4F9] mt-2">{tool.title}</div>
						</div>
					))}
				</div>
			</Modal>
		</div>
	)
}

export default AdvancedAnalystCard

AdvancedAnalystCard.propTypes = {
	items: PropTypes.array,
}

AdvancedAnalystCard.defaultProps = {
	items: null,
}
