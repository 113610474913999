import { buildCreateApi, coreModule, fetchBaseQuery, reactHooksModule } from '@reduxjs/toolkit/query/react'
import { configureStore } from '@reduxjs/toolkit'
import { createContext } from 'react'
import { createDispatchHook, createSelectorHook } from 'react-redux'
import axios from 'axios'

export const apiContext = createContext(null)

const useDispatch = createDispatchHook(apiContext)
const useSelector = createSelectorHook(apiContext)
const useStore = () => apiStore

const createApi = buildCreateApi(coreModule(), reactHooksModule({ useSelector, useDispatch, useStore }))
const preparedHeders = (headers) => {
	headers.set('accept', '*/*')
	const token = localStorage.getItem('access_token')
	if (token) headers.set('Authorization', `Bearer ${JSON.parse(token)}`)
	return headers
}

const baseAuthQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_AUTH_BASE_URL,
	prepareHeaders: preparedHeders,
})

const baseGPTQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_GPT_BASE_URL,
	prepareHeaders: preparedHeders,
})

const baseKBQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_KB_BASE_URL,
	prepareHeaders: preparedHeders,
})

const baseAuthQueryWithReauth = async (args, api, extraOptions) => {
	try {
		let result = await baseAuthQuery(args, api, extraOptions)
		if (result.error && result.error.status === 401) {
			// if (result.error && (result.error.status === 401 || result.error.status === 403)) {
			const refreshResult = await axios(process.env.REACT_APP_API_AUTH_BASE_URL + '/v1/auth/verify', {
				method: 'POST',
				data: JSON.parse(localStorage.getItem('access_token')),
			})
			if (refreshResult?.data?.access_token) {
				localStorage.setItem('access_token', JSON.stringify(refreshResult?.data?.access_token))
				result = await baseAuthQuery(args, api, extraOptions)
			} else {
				window.location.href = '/auth/login'
				localStorage.removeItem('access_token')
			}
		}
		return result
	} catch (error) {
		window.location.href = '/auth/login'
		localStorage.removeItem('access_token')
	}
}

const baseGPTQueryWithReauth = async (args, api, extraOptions) => {
	try {
		let result = await baseGPTQuery(args, api, extraOptions)
		if (result.error && result.error.status === 401) {
			// if (result.error && (result.error.status === 401 || result.error.status === 403)) {
			const refreshResult = await axios(process.env.REACT_APP_API_AUTH_BASE_URL + '/v1/auth/verify', {
				method: 'POST',
				data: JSON.parse(localStorage.getItem('access_token')),
			})
			if (refreshResult?.data?.access_token) {
				localStorage.setItem('access_token', JSON.stringify(refreshResult?.data?.access_token))
				result = await baseGPTQuery(args, api, extraOptions)
			} else {
				window.location.href = '/auth/login'
				localStorage.removeItem('access_token')
			}
		}
		return result
	} catch (error) {
		window.location.href = '/auth/login'
		localStorage.removeItem('access_token')
	}
}

const baseKBQueryWithReauth = async (args, api, extraOptions) => {
	try {
		let result = await baseKBQuery(args, api, extraOptions)
		if (result.error && result.error.status === 401) {
			// if (result.error && (result.error.status === 401 || result.error.status === 403)) {
			const refreshResult = await axios(process.env.REACT_APP_API_AUTH_BASE_URL + '/v1/auth/verify', {
				method: 'POST',
				data: JSON.parse(localStorage.getItem('access_token')),
			})
			if (refreshResult?.data?.access_token) {
				localStorage.setItem('access_token', JSON.stringify(refreshResult?.data?.access_token))
				result = await baseKBQuery(args, api, extraOptions)
			} else {
				window.location.href = '/auth/login'
				localStorage.removeItem('access_token')
			}
		}
		return result
	} catch (error) {
		window.location.href = '/auth/login'
		localStorage.removeItem('access_token')
	}
}

export const apiAuth = createApi({
	reducerPath: 'apiAuth',
	baseQuery: baseAuthQueryWithReauth,
	endpoints: () => ({}),
})

export const apiGPT = createApi({
	reducerPath: 'apiGPT',
	baseQuery: baseGPTQueryWithReauth,
	endpoints: () => ({}),
})

export const apiKB = createApi({
	reducerPath: 'apiKB',
	baseQuery: baseKBQueryWithReauth,
	endpoints: () => ({}),
})

export const apiStore = configureStore({
	reducer: {
		apiAuth: apiAuth.reducer,
		apiGPT: apiGPT.reducer,
		apiKB: apiKB.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
			.concat(apiAuth.middleware)
			.concat(apiGPT.middleware)
			.concat(apiKB.middleware),
})
