import styled from '@xstyled/styled-components'

export const Root = styled.div`
	padding: 16px;
`
export const ToolsBadge = styled.div`
	background-color: '#5A47CD';
	text-color: 'white'
	border-radius: 50%;
	width: 28px;
	height: 20px;
	font-size: 12px;
	text-align: center;
	margin-top: 4px;
`
