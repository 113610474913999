import {
	AppstoreOutlined,
	ArrowRightOutlined,
	BarChartOutlined,
	DatabaseOutlined,
	MessageOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Select, Space, Tag } from 'antd'
import { presetPalettes } from '@ant-design/colors'
import React, { useState } from 'react'

const VersionFormItems = ({ form }) => {
	return <div>version</div>
}

export default VersionFormItems
