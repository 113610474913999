import { createSlice } from '@reduxjs/toolkit'

const toolsSlice = createSlice({
	name: 'Tools',
	initialState: { isReady: false },
	reducers: {
		startup(state) {},
		startupSuccess(state) {
			state.isReady = true
		},
		startupFailure() {},
	},
})

export const { startupSuccess, startup, startupFailure } = toolsSlice.actions

export default toolsSlice.reducer
