import { fork, put, takeLatest } from 'redux-saga/effects'
import { startup, startupFailure, startupSuccess } from 'modules/chat/reducers/chatReducer'

function* watchStartup() {
	yield takeLatest(startup.type, startupSaga)
}

function* startupSaga() {
	try {
		yield put(startupSuccess())
	} catch (err) {
		yield put(startupFailure(err))
	}
}

export default function* chatSaga() {
	yield fork(watchStartup)
}
