import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { PaginationButton, ToolsBadge } from 'modules/dashboard/components/__styles__/DashboardPage.styles'
import { selectIsReady } from 'modules/dashboard/selectors/dashboardSelectors'
import PopularGenerator from 'modules/dashboard/components/PopularGenerator'
import RecentGenerator from 'modules/dashboard/components/RecentGenerator'
import SavedGenerator from 'modules/dashboard/components/SavedGenerator'

import { useGetDashboardDocumentsQuery, useGetDashboardToolsQuery } from 'shared/api/dashboardApi'

import Loader from 'shared/components/loader/Loader'

import * as Styled from 'modules/dashboard/components/__styles__/DashboardPage.styles'

const DashboardPage = () => {
	const isReady = useSelector(selectIsReady)
	const [popularOffset, setPopularOffset] = useState(0)
	const [savedOffset, setSavedOffset] = useState(0)
	const maxDisplay = 5

	const { data: popularTools } = useGetDashboardToolsQuery({
		orderby: 'popularity',
		order: 'desc',
		offset: popularOffset,
		limit: maxDisplay,
	})

	const { data: recentTools } = useGetDashboardToolsQuery({
		orderby: 'last_used',
		order: 'desc',
		offset: 0,
		limit: 10,
	})

	const { data: savedContents } = useGetDashboardDocumentsQuery({
		filter_field: 'is_saved',
		filter_value: true,
		order: 'desc',
		offset: 0,
		limit: maxDisplay,
	})

	return isReady ? (
		<Styled.Root className="space-y-4">
			{!recentTools && <Loader />}
			{recentTools && recentTools.length > 0 && (
				<div>
					<div className="flex gap-2 px-6 mb-4 text-xl font-medium">
						Favorites <ToolsBadge>{recentTools.length}</ToolsBadge>
					</div>
					<div className="flex gap-4">
						{recentTools.map((tool, i) => (
							<RecentGenerator key={i} tool={tool} i={i} />
						))}
					</div>
				</div>
			)}
			{!savedContents && <Loader />}
			{savedContents && savedContents.length > 0 && (
				<>
					<div className="px-6 space-x-2 flex items-center">
						<span className="text-xl font-medium">Saved</span>
						<ToolsBadge>{savedContents.length}</ToolsBadge>
					</div>
					<div className="flex gap-4">
						{savedOffset > 0 && (
							<PaginationButton onClick={() => setSavedOffset(savedOffset - maxDisplay)}>
								<AiOutlineLeft />
							</PaginationButton>
						)}
						{savedContents && savedContents.map((document, i) => <SavedGenerator key={i} document={document} />)}
						{savedContents.length === maxDisplay && (
							<PaginationButton next={true} onClick={() => setSavedOffset(savedOffset + maxDisplay)}>
								<AiOutlineRight />
							</PaginationButton>
						)}
					</div>
				</>
			)}
			{!popularTools && <Loader />}
			{popularTools && popularTools.length > 0 && (
				<>
					<div className="px-6 text-xl font-medium">Popular</div>
					<div className="flex gap-4">
						{popularOffset > 0 && (
							<PaginationButton onClick={() => setPopularOffset(popularOffset - maxDisplay)}>
								<AiOutlineLeft />
							</PaginationButton>
						)}
						{popularTools && popularTools.map((tool, i) => <PopularGenerator key={i} tool={tool} i={i} />)}
						{popularTools.length === maxDisplay && (
							<PaginationButton next={true} onClick={() => setPopularOffset(popularOffset + maxDisplay)}>
								<AiOutlineRight />
							</PaginationButton>
						)}
					</div>
				</>
			)}
		</Styled.Root>
	) : (
		<Loader />
	)
}

export default DashboardPage

DashboardPage.propTypes = {
	/** Content to display if there are no errors. */
	children: PropTypes.node,
}

DashboardPage.defaultProps = {
	children: null,
}
