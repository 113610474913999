import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React from 'react'

import { selectIsReady } from 'modules/tools/selectors/toolsSelectors'

import Loader from 'shared/components/loader/Loader'

import * as Styled from 'modules/tools/components/__styles__/ToolsPage.styles'

const ToolsPage = () => {
	const isReady = useSelector(selectIsReady)

	return isReady ? (
		<Styled.Root>
			<Outlet />
		</Styled.Root>
	) : (
		<Loader />
	)
}

export default ToolsPage
