import { FavoriteText, FavoriteToolCard } from 'modules/dashboard/components/__styles__/DashboardPage.styles'
import { presetPalettes } from '@ant-design/colors'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import ToolIcon from 'modules/tools/components/icon/ToolIcon'

const RecentGenerator = ({ tool, i }) => {
	const navigate = useNavigate()
	return (
		<div>
			<FavoriteToolCard
				onClick={() => {
					navigate(`/app/tools/render/${tool.id}`)
				}}
			>
				<ToolIcon type={tool.logo_id} color={Object.values(presetPalettes)[i][i]} />
			</FavoriteToolCard>
			<FavoriteText>{tool.title}</FavoriteText>
		</div>
	)
}

export default RecentGenerator
