import { Tag, Typography } from 'antd'
import { presetPalettes } from '@ant-design/colors'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import ToolIcon from 'modules/tools/components/icon/ToolIcon'

const colors = ['volcano', 'blue', 'green']

const PopularGenerator = ({ tool, i }) => {
	const navigate = useNavigate()
	return (
		<div
			onClick={() => {
				navigate(`/app/tools/render/${tool.id}`)
			}}
			className="cursor-pointer card p-6 space-y-2 max-w-[388px] min-h-[222px] flex flex-col"
		>
			<div className="flex items-center justify-between">
				<ToolIcon type={tool.logo_id} color={Object.values(presetPalettes)[i]} />
				<div className="space-x-1">
					{tool.tags?.map((tag, j) => (
						<Tag color={colors[j % colors.length]} key={j}>
							{tag}
						</Tag>
					))}
				</div>
			</div>
			<div className="text-xl font-medium">{tool.title}</div>
			<div className="flex-1">
				<Typography.Text>{tool.description}</Typography.Text>
			</div>
			<div>
				<Typography.Text type="secondary">
					v {tool.version} {tool.nb_contents} usages
				</Typography.Text>
			</div>
		</div>
	)
}

export default PopularGenerator
