import { Link } from 'react-router-dom'
import React from 'react'

import * as Styled from 'shared/components/layout/__styles__/Footer.styles'

const AuthFooter = () => {
	return (
		<Styled.Root className="bg-[#ffffff]">
			<div className="mt-8 flex justify-center">
				<div className="grid grid-cols-8 sm:gap-8 gap-2 sm:text-center text-left">
					<div className="sm:col-span-2 col-span-10 text-xs text-[#00000099]">
						© 2023 GNOMON DIGITAL. All rights reserved.
					</div>
					<Link to="/terms" className="sm:col-span-2 col-span-5 text-xs text-[#00000099]">
						Terms of Service
					</Link>
					<Link to="/privacy_policy" className="sm:col-span-2 col-span-5 text-xs text-[#00000099]">
						Privacy Policy
					</Link>
					<Link to="/disclosure" className="sm:col-span-2 col-span-5 text-xs text-[#00000099]">
						Responsible Disclosure
					</Link>
				</div>
			</div>
		</Styled.Root>
	)
}

export default AuthFooter
