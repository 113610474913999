import { apiGPT } from './index'

export const MonitoringsApi = apiGPT.injectEndpoints({
	endpoints: (build) => ({
		getGlobalStats: build.query({
			providesTags: () => ['globalStats'],
			query: (params) => ({
				url: '/monitorings/globalStats',
			}),
		}),
	}),
})

export const { useGetGlobalStatsQuery } = MonitoringsApi
