import { Button, Result } from 'antd'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import React, { useLayoutEffect } from 'react'

import AuthPage from 'modules/auth'
import ChatPage from 'modules/chat'
import CreateToolPage from 'modules/tools/components/CreateToolPage'
import DashboardPage from 'modules/dashboard'
import HomePage from 'modules/home'
import KnowledgeBasePage from 'modules/knowledge/'
import KnowledgeCategoryPage from 'modules/knowledge/components/KnowledgeCategoryPage'
import KnowledgeDetailsPage from 'modules/knowledge/components/KnowledgeDetailsPage'
import KnowledgeListPage from 'modules/knowledge/components/KnowledgeListPage'
import LoginPage from 'modules/auth/components/LoginPage'
import MonitorPage from 'modules/monitor'
import RegisterPage from 'modules/auth/components/RegisterPage'
import ToolDetailsPage from 'modules/tools/components/ToolDetailsPage'
import ToolRendererPage from 'modules/tools/components/ToolRendererPage'
import ToolsListPage from 'modules/tools/components/ToolsListPage'
import ToolsPage from 'modules/tools'

import { GoogleOAuthProvider } from '@react-oauth/google'
import AppLayout from 'shared/components/layout/AppLayout'
import AuthLayout from 'shared/components/layout/AuthLayout'
import DisclosurePage from 'modules/DisclosurePage'
import ErrorBoundary from 'shared/components/error/ErrorBoundary'
import HubSpotScript from 'shared/components/hubspot/HubSpotScript'
import Layout from 'shared/components/layout/Layout'
import PrivacyPolicyPage from 'modules/PrivacyPolicyPage'
import PrivateRoutes from 'shared/utils/privateRoutes'
import TermsPage from 'modules/TermsPage'

const App = () => {
	const location = useLocation()
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<GoogleOAuthProvider clientId="">
			<HubSpotScript />
			<ErrorBoundary>
				<Routes>
					<Route element={<PrivateRoutes />}>
						<Route path="app" element={<AppLayout />}>
							<Route index element={<DashboardPage />} />
							<Route path="chat" element={<ChatPage />} />
							<Route path="monitor" element={<MonitorPage />} />
							<Route path="knowledge" element={<KnowledgeBasePage />}>
								<Route index element={<KnowledgeListPage />} />
								<Route path="search" element={<KnowledgeDetailsPage />} />
								<Route path="documents" element={<KnowledgeCategoryPage />} />
							</Route>
							<Route path="tools" element={<ToolsPage />}>
								<Route index element={<ToolsListPage />} />
								<Route path="new" element={<CreateToolPage />} />
								<Route path="render/:id" element={<ToolRendererPage />} />
								<Route path=":id" element={<ToolDetailsPage />} />
							</Route>
						</Route>
					</Route>

					<Route path="/" element={<Layout />}>
						<Route index element={<HomePage />} />
						<Route path="home" element={<HomePage />} />
					</Route>

					<Route path="/" element={<AuthLayout />}>
						<Route path="terms" element={<TermsPage />} />
						<Route path="privacy_policy" element={<PrivacyPolicyPage />} />
						<Route path="disclosure" element={<DisclosurePage />} />
						<Route path="auth" element={<AuthPage />}>
							<Route path="register" element={<RegisterPage />} />
							<Route path="login" element={<LoginPage />} />
						</Route>
					</Route>
					<Route
						path="*"
						element={
							<Result
								status="404"
								title="404"
								subTitle="Sorry, the page you visited does not exist."
								extra={
									<Link to="/app">
										<Button type="primary" size="large">
											Back Home
										</Button>
									</Link>
								}
							/>
						}
					/>
				</Routes>
			</ErrorBoundary>
		</GoogleOAuthProvider>
	)
}

export default App
