import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Input, Skeleton, Tabs } from 'antd'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { RiSearchLine } from 'react-icons/ri'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import emptyIcon from 'assets/images/empty.svg'

import { useQueryDocumentsMutation } from 'shared/api/documentApi'

import AddKnowledgeFormModal from 'modules/knowledge/components/modal/AddKnowledgeFormModal'
import AdvancedAnalystCard from 'modules/knowledge/components/card/AdvancedAnalystCard'
import ContentCard from 'modules/knowledge/components/card/ContentCard'

const KnowledgeDetailsPage = () => {
	const [selectItem, setSelectItem] = useState([])
	const [documentData, setDocumentData] = useState([])

	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const [queryDocuments, { isLoading: isGetDocumentFetching }] = useQueryDocumentsMutation()

	useEffect(() => {
		const fetchData = async () => {
			const result = await queryDocuments({
				queries: [
					{
						query: searchParams.get('query') ? searchParams.get('query') : '',
						filter: {
							access_level: searchParams.get('access_level'),
						},
					},
				],
			})
			setDocumentData(result?.data?.results?.[0]?.results)
		}
		void fetchData()
		return () => {}
	}, [searchParams, queryDocuments])

	const _onSelectClick = (value) => {
		if (selectItem.map((i) => i.id).includes(value.id)) {
			setSelectItem((prevState) => prevState.filter((i) => i.id !== value.id))
		} else {
			setSelectItem((prevState) => [...prevState, value])
		}
	}

	const _onSearch = (v) => navigate(`/app/knowledge/search?query=${v}`)

	return (
		<div>
			<div className="card pt-4 px-6 relative">
				<div className="absolute space-x-4 right-8 flex items-center">
					<AddKnowledgeFormModal />
					{/*<Button className="flex items-center">*/}
					{/*	<ShareAltOutlined />*/}
					{/*	Send a request*/}
					{/*</Button>*/}
				</div>
				<Breadcrumb items={[{ title: <Link to="/app/knowledge">Knowledge base</Link> }, { title: 'Search' }]} />
				<Link to="/app/knowledge" className="flex items-center space-x-2 my-2">
					<ArrowLeftOutlined className="text-[#000]" />
					<span className="text-xl text-[#000]">{searchParams.get('query')}</span>
				</Link>
				<Tabs
					activeKey={searchParams.get('access_level') || 'all'}
					onChange={(key) => {
						searchParams.set('access_level', key)
						navigate({
							pathname: '',
							search: searchParams.toString(),
						})
					}}
					items={[
						{ key: 'all', label: `All` },
						{ key: 'public', label: `Public` },
						{ key: 'team', label: `Team` },
						{ key: 'personal', label: `Personal` },
					]}
				/>
				<Input.Search
					onSearch={_onSearch}
					className="max-w-[474px] absolute right-6 bottom-4"
					placeholder="Provide me a detailed description here..."
					enterButton={<RiSearchLine />}
				/>
			</div>
			<div className="max-w-[792px] m-auto">
				<div className="text-xl px-6 py-4">
					{isGetDocumentFetching ? <Skeleton.Button /> : documentData?.length} documents
				</div>
				<div className="space-y-4">
					{documentData?.map((item, i) => (
						<ContentCard
							data={item}
							key={i}
							onSelect={_onSelectClick}
							isSelect={selectItem?.map((i) => i.id).includes(item.id)}
						/>
					))}
				</div>

				{!isGetDocumentFetching && documentData?.length === 0 && (
					<div className="bg-white text-center py-36">
						<img src={emptyIcon} alt="emptyIcon" className="m-auto mb-4" />
						<div>There is no record for now</div>
					</div>
				)}
			</div>
			<AdvancedAnalystCard items={selectItem} setSelectItem={setSelectItem} />
		</div>
	)
}

export default KnowledgeDetailsPage

KnowledgeDetailsPage.propTypes = {
	/** Content to display if there are no errors. */
	children: PropTypes.node,
}

KnowledgeDetailsPage.defaultProps = {
	children: null,
}
