import {
	AppstoreOutlined,
	ArrowRightOutlined,
	BarChartOutlined,
	DatabaseOutlined,
	MessageOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Select, Space, Tag } from 'antd'
import { presetPalettes } from '@ant-design/colors'
import React, { useState } from 'react'

const InfoFromItems = ({ form }) => {
	const [tagValueChange, onTagValueChange] = useState()
	const layout = { labelCol: { span: 6 }, wrapperCol: { span: 12 } }

	return (
		<div className="m-auto">
			<Form.Item {...layout} label="Title" name="title" rules={[{ required: true, message: 'Please input title!' }]}>
				<Input placeholder="Avatar name generator" />
			</Form.Item>
			<Form.Item
				{...layout}
				label="Description"
				name="description"
				rules={[{ required: true, message: 'Please input description!' }]}
			>
				<Input.TextArea placeholder="Generate random name by selecting country and gender" />
			</Form.Item>
			<Form.Item
				{...layout}
				label="Template"
				name="template"
				rules={[{ required: true, message: 'Please input template!' }]}
			>
				<Input.TextArea placeholder="Tell me a story about {{ subject }}, and translate it in language {{ lang }}" />
			</Form.Item>
			<Form.Item {...layout} label="Icon" name="logo_id" wrapperCol={{ span: 3 }}>
				<Select
					size="large"
					options={[
						{ value: 'UserOutlined', label: <UserOutlined /> },
						{ value: 'AppstoreOutlined', label: <AppstoreOutlined /> },
						{ value: 'ArrowRightOutlined', label: <ArrowRightOutlined /> },
						{ value: 'BarChartOutlined', label: <BarChartOutlined /> },
						{ value: 'DatabaseOutlined', label: <DatabaseOutlined /> },
						{ value: 'MessageOutlined', label: <MessageOutlined /> },
					]}
				/>
			</Form.Item>
			<Form.List name="tags">
				{(fields, { add, remove }) => (
					<>
						<div>
							<div>
								<Form.Item {...layout} label={'Tag'}>
									<Space size={[0, 8]} wrap>
										{fields.map((field) => (
											<Tag
												key={field.key}
												color={Object.values(presetPalettes)[field.key][4]}
												closable
												onClose={() => remove(field.name)}
											>
												{form.getFieldsValue()?.['tags'][field.name]}
											</Tag>
										))}
									</Space>
								</Form.Item>
							</div>

							<Form.Item {...layout} label={' '} colon={false}>
								<div className="flex items-center space-x-2">
									<Input value={tagValueChange} onChange={(e) => onTagValueChange(e.target.value)} />
									<Button
										disabled={!tagValueChange}
										onClick={() => {
											add(tagValueChange)
											onTagValueChange()
										}}
									>
										Add item
									</Button>
								</div>
							</Form.Item>
						</div>
					</>
				)}
			</Form.List>
		</div>
	)
}

export default InfoFromItems
