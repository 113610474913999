import { Button, Input, Layout, Spin, Typography } from 'antd'
import { LoadingOutlined, SendOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { selectIsReady } from 'modules/chat/selectors/chatSelectors'

import { useSearchParams } from 'react-router-dom'
import ChatContentContainer from 'modules/chat/components/content/ChatContentContainer'
import ConversationHeader from 'modules/chat/components/header/ConversationHeader'
import ConversationsSider from 'modules/chat/components/sider/ConversationsSider'
import ToolRender from 'modules/chat/components/ToolRender'

import {
	useGetContentsQuery,
	useGetConversationsQuery,
	useGetModelsQuery,
	usePostConversationsMutation,
	usePostToolsRendersMutation,
} from 'shared/api/chatApi'
import { useGetDocumentsQuery } from 'shared/api/documentApi'

import chat_avatar from 'assets/images/chat_avatar.svg'

import Loader from 'shared/components/loader/Loader'

import * as Styled from 'modules/chat/components/__styles__/ChatPage.styles'
import { isEmpty } from 'lodash'
import pdf from '../../../assets/images/pdf.svg'

const ChatPage = () => {
	let [searchParams] = useSearchParams()
	const { data: chatData } = useGetConversationsQuery({ offset: 0, limit: 100 })
	const conversations = chatData?.filter((v) => v.prompt_tpl_id === '')

	const [postToolsRenders, { isLoading: isPostToolsRendersLoading }] = usePostToolsRendersMutation()
	const [postConversations] = usePostConversationsMutation()
	const { data: documentData } = useGetDocumentsQuery({
		refetchOnMountOrArgChange: true,
	})

	const isReady = useSelector(selectIsReady)

	const [inputValue, setInputValue] = useState()
	const [currentChatItem, setCurrentChatItem] = useState()
	const [isUseBaseData, setIsUseBaseData] = useState(true)

	const { data: modelData } = useGetModelsQuery()
	const { data: historyData } = useGetContentsQuery({
		filter_field: 'conversation_id',
		filter_value: currentChatItem,
		offset: 0,
		limit: 100,
	})

	const [currentModel, setCurrentModel] = useState()

	useEffect(() => {
		if (searchParams.get('id')) {
			setCurrentChatItem(searchParams.get('id'))
		}
	}, [searchParams])

	useEffect(() => {
		setCurrentModel(modelData?.[0]?.id)
	}, [modelData])

	const currentChatDocuments = useMemo(() => {
		const ids = conversations?.find((i) => i.conversation_id === currentChatItem)?.document_ids
		return documentData?.filter((document) => ids?.includes(document.id))
	}, [documentData, conversations, currentChatItem])

	const onInputChange = (e) => {
		setInputValue(e.target.value)
	}

	const _onSubmit = async () => {
		const params = {
			model_id: currentModel,
			prompt_tpl_id: '',
			render_source_type: 'chatbot',
			placeholder_params: {},
			with_kb_contexts: isUseBaseData,
			message: inputValue,
		}
		if (currentChatItem) {
			await postToolsRenders({ ...params, conversation_id: currentChatItem })
		} else {
			const newConversation = await postConversations({ title: inputValue, prompt_tpl_id: '' })
			await postToolsRenders({ ...params, conversation_id: newConversation?.data?.conversation_id })

			setCurrentChatItem(newConversation?.data?.conversation_id)
		}
		setInputValue()
	}

	const _onCheckboxChange = (e) => setIsUseBaseData(e.target.checked)

	const _onSelectChat = (id) => setCurrentChatItem(id)

	return isReady ? (
		<Styled.Root>
			<Styled.Layout>
				<ConversationsSider
					conversations={conversations}
					onSelect={_onSelectChat}
					isPostToolsRendersLoading={isPostToolsRendersLoading}
					currentChatItem={currentChatItem}
				/>
				<Layout>
					<ConversationHeader
						isUseBaseData={isUseBaseData}
						currentModel={currentModel}
						onSelect={setCurrentModel}
						onCheck={_onCheckboxChange}
					/>
					<Layout.Content>
						<div className="relative">
							{isPostToolsRendersLoading && (
								<span className="relative top-[100px] inset-2/4">
									<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
								</span>
							)}
							{currentChatItem && (
								<>
									{!isEmpty(currentChatDocuments) && (
										<>
											<div className="py-4 px-6 bg-[#FAFAFA] space-x-4 flex items-start">
												<span className="min-w-[30px]">
													<img src={chat_avatar} alt="chat" />
												</span>
												<div className="w-full">
													<div>Debrief and analysis the selected contents</div>
													<div className="flex items-center space-x-2 mt-2">
														{currentChatDocuments.map((data, i) => (
															<div key={i} className="bg-[#F5F5F5] p-2">
																<div className="flex space-x-2 items-center">
																	<img src={pdf} alt="pdf" />
																	<span className="text-sm">{data?.metadata?.filename}</span>
																</div>
															</div>
														))}
													</div>
												</div>
											</div>
										</>
									)}
									{historyData?.map((item, i) => {
										const isUser = item.role === 'user'
										const icon = isUser ? (
											<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="0.0976562" y="0.839844" width="30" height="30" rx="2" fill="#5A47CD" />
												<path
													d="M23.0977 23.8398V21.8398C23.0977 20.779 22.6762 19.7616 21.9261 19.0114C21.1759 18.2613 20.1585 17.8398 19.0977 17.8398H11.0977C10.0368 17.8398 9.01937 18.2613 8.26923 19.0114C7.51908 19.7616 7.09766 20.779 7.09766 21.8398V23.8398"
													stroke="white"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
												<path
													d="M15.0977 13.8398C17.3068 13.8398 19.0977 12.049 19.0977 9.83984C19.0977 7.6307 17.3068 5.83984 15.0977 5.83984C12.8885 5.83984 11.0977 7.6307 11.0977 9.83984C11.0977 12.049 12.8885 13.8398 15.0977 13.8398Z"
													stroke="white"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										) : (
											<img src={chat_avatar} alt="chat" />
										)
										return (
											<div
												key={i}
												className={
													isUser
														? 'py-4 px-6 space-x-4 flex items-center'
														: 'py-4 px-6 bg-[#FAFAFA] space-x-4 flex items-start'
												}
											>
												<span className="min-w-[30px]">{icon}</span>
												<div className="w-full">
													<Typography.Text type="secondary">{dayjs(item.created_at).format('LLL')}</Typography.Text>
													<ChatContentContainer item={item} isResponse={!isUser} />
												</div>
											</div>
										)
									})}
								</>
							)}
						</div>
					</Layout.Content>
					<Layout.Footer>
						<div className="relative">
							<Input
								className="h-[64px]"
								prefix={<ToolRender currentChatItem={currentChatItem} setCurrentChatItem={setCurrentChatItem} />}
								value={inputValue}
								size="large"
								placeholder="Send a message"
								allowClear
								onChange={onInputChange}
								onPressEnter={_onSubmit}
								disabled={isPostToolsRendersLoading}
							/>
							<Button
								loading={isPostToolsRendersLoading}
								type="text"
								className="absolute right-[30px] top-[14px] z-[1000]"
							>
								<SendOutlined className="text-[24px]" onClick={_onSubmit} />
							</Button>
						</div>
					</Layout.Footer>
				</Layout>
			</Styled.Layout>
		</Styled.Root>
	) : (
		<Loader />
	)
}

export default ChatPage

ChatPage.propTypes = {
	/** Content to display if there are no errors. */
	children: PropTypes.node,
}

ChatPage.defaultProps = {
	children: null,
}
