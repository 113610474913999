import { Button, Layout, Modal } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

import { useDeleteConversationMutation } from 'shared/api/chatApi'

const ConversationsSider = ({ conversations, isPostToolsRendersLoading, currentChatItem, onSelect }) => {
	const [isHoverId, setIsHoverId] = useState()

	const [deleteConversation] = useDeleteConversationMutation()

	return (
		<Layout.Sider theme="light" width="358px">
			<div className="space-y-2">
				<Button
					loading={isPostToolsRendersLoading}
					type="text"
					className="flex items-center space-x-4 text-[#5A47CD] h-[56px] w-full"
					onClick={() => onSelect()}
				>
					<PlusOutlined className="text-[20px]" />
					<span className="font-medium" />
				</Button>
				{conversations?.map((chatItem, i) => {
					const isCurrent = chatItem.conversation_id === currentChatItem

					return (
						<Button
							onMouseOver={() => setIsHoverId(chatItem.conversation_id)}
							onMouseLeave={() => setIsHoverId()}
							onClick={() => onSelect(chatItem.conversation_id)}
							type="text"
							key={i}
							className={
								isCurrent
									? 'relative rounded-lg bg-[#F5F0FF] flex items-center space-x-4 text-[#5A47CD] h-[56px] w-full'
									: 'relative rounded-lg flex items-center space-x-4 text-[#000000d9] h-[56px] w-full'
							}
						>
							{isHoverId === chatItem.conversation_id && (
								<span
									className="absolute right-[-6px] top-[-12px] inline-block"
									onClick={(e) => {
										Modal.confirm({
											title: `Delete the conversation - ${chatItem?.title}?`,
											onOk() {
												deleteConversation(chatItem.conversation_id)
											},
											okButtonProps: {
												style: { backgroundColor: '#5A47CD' },
											},
										})
										e.stopPropagation()
									}}
								>
									<CloseCircleOutlined className="text-[#5A47CD] text-[20px]" />
								</span>
							)}
							<svg
								className="cursor-pointer text-[20px] min-w-[20px]"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 18 16"
								width="18"
								height="16"
								fill="none"
							>
								<path
									d="M0.666748 5.49478C0.666748 2.73624 2.8972 0.5 5.6673 0.5H12.3328C15.0946 0.5 17.3334 2.74565 17.3334 5.49478V15.5H5.6673C2.90557 15.5 0.666748 13.2543 0.666748 10.5052V5.49478ZM15.6667 13.8333V5.49478C15.6667 3.66283 14.1708 2.16667 12.3328 2.16667H5.6673C3.82056 2.16667 2.33341 3.65382 2.33341 5.49478V10.5052C2.33341 12.3372 3.82935 13.8333 5.6673 13.8333H15.6667ZM10.6667 7.16667H12.3334V8.83333H10.6667V7.16667ZM5.66675 7.16667H7.33341V8.83333H5.66675V7.16667Z"
									fill="currentColor"
									fillOpacity="0.9"
								/>
							</svg>
							<span className="font-medium text-ellipsis overflow-hidden">{chatItem?.title}</span>
						</Button>
					)
				})}
			</div>
		</Layout.Sider>
	)
}

export default ConversationsSider
