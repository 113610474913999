import { DynamicModuleLoader } from 'redux-dynamic-modules'
import React from 'react'

import dashboardReducer, { startup } from 'modules/dashboard/reducers/dashboardReducer'

import dashboardSaga from 'modules/dashboard/sagas/dashboardSaga'

import DashboardPage from 'modules/dashboard/components/DashboardPage'

const moduleToLoad = {
	id: 'dashboard',
	reducerMap: { dashboard: dashboardReducer },
	sagas: [dashboardSaga],
	initialActions: [startup()],
}

export default function DynamicDashboardPage(props) {
	return (
		<DynamicModuleLoader modules={[moduleToLoad]}>
			<DashboardPage {...props} />
		</DynamicModuleLoader>
	)
}
