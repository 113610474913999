import { Avatar, Button, Form, Input, Select, Space, Typography, message } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { RiNotification2Line, RiQuestionLine, RiSettings3Line } from 'react-icons/ri'
import { capitalize } from 'lodash'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'

import { logout } from 'modules/root/reducers/rootReducer'

import { useGetUserQuery } from 'shared/api/userApi'
import { usePostContactsMutation } from 'shared/api/chatApi'

import * as Styled from 'shared/components/layout/__styles__/AppHeader.styles'

const AppHeader = ({ setShowPanel }) => {
	const { data: user } = useGetUserQuery()

	const [form] = Form.useForm()
	const [contactForm] = Form.useForm()

	const [postContacts] = usePostContactsMutation()

	const dispatch = useDispatch()

	useEffect(() => {
		user && form.setFieldsValue(user)
	}, [user, form])

	const _onContactFinish = (values) => {
		postContacts(values)
		contactForm.resetFields()
		setShowPanel(false)
		message.success('Successfully send message!')
	}

	const _onResetClick = () => {
		form.resetFields({ first_name: '', last_name: '', email: '', introduction: '' })
	}
	const _onLogoutClick = () => {
		dispatch(logout())
	}

	const renderBody = (page) => {
		if (page === 'profile') {
			return (
				<div>
					<Form
						form={form}
						initialValues={{
							introduction: 'I work as a data engineer at a leading technology company.',
							organization: 'Operation/IT/Service',
						}}
						//	onFinish={_onFinish}
						layout={'vertical'}
						//onValuesChange={_onFormChange}
					>
						<div style={{ display: 'flex', justifyContent: 'center', padding: '8px 0px' }}>
							<div
								style={{
									width: 88,
									height: 88,
									borderRadius: 88,
									backgroundColor: ' #5a47cd',
									padding: 5,
								}}
							>
								<div
									style={{
										fontSize: 48,
										textAlign: 'center',
										color: 'white',
										fontWeight: 400,
									}}
								>
									{capitalize(user?.first_name.slice(0, 1))}
								</div>
							</div>
						</div>

						<Form.Item
							label={<label style={{ color: 'gray', fontSize: 12 }}>First name</label>}
							name="first_name"
							//rules={[{ required: true, message: 'Please input username!' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={<label style={{ color: 'gray', fontSize: 12 }}>Last name</label>}
							name="last_name"
							//rules={[{ required: true, message: 'Please input username!' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={<label style={{ color: 'gray', fontSize: 12 }}>Introduction</label>}
							name="introduction"
							//rules={[{ required: true, message: 'Please input username!' }]}
						>
							<Input.TextArea />
						</Form.Item>
						<Form.Item
							label={<label style={{ color: 'gray', fontSize: 12 }}>Email</label>}
							name="email"
							rules={[{ type: 'email', message: 'Please type a valid email' }]}
						>
							<Input />
						</Form.Item>
						<Form.Item label={<label style={{ color: 'gray', fontSize: 12 }}>Role</label>} name="role">
							<Input disabled={true} />
						</Form.Item>
						<Form.Item label={<label style={{ color: 'gray', fontSize: 12 }}>Organization</label>} name="organization">
							<Input disabled={true} />
						</Form.Item>
						<div style={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
							<Button style={{ width: '100%' }} size="large" onClick={_onResetClick}>
								Reset
							</Button>
							<Button htmlType="submit" style={{ width: '100%' }} type="primary" size="large">
								Save
							</Button>
						</div>
						<Button style={{ width: '100%', marginTop: '80px' }} size="large" onClick={_onLogoutClick}>
							Logout
						</Button>
					</Form>
				</div>
			)
		}
		if (page === 'settings') {
			return (
				<div>
					<Form
						//	form={form}
						initialValues={{
							language: 'en',
							token: '',
						}}
						//	onFinish={_onFinish}
						layout={'vertical'}
						//onValuesChange={_onFormChange}
					>
						<Form.Item
							label={<label style={{ color: 'gray', fontSize: 12 }}>Language</label>}
							name="language"
							//rules={[{ required: true, message: 'Please input username!' }]}
						>
							<Select defaultValue={'en'} options={[{ value: 'en', label: 'English' }]} />
						</Form.Item>
						<Form.Item
							label={<label style={{ color: 'gray', fontSize: 12 }}>Put your own Open API token</label>}
							name="token"
							//rules={[{ required: true, message: 'Please input username!' }]}
						>
							<Input />
						</Form.Item>
						<div style={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
							<Button style={{ width: '100%' }} size="large">
								Reset
							</Button>
							<Button htmlType="submit" style={{ width: '100%' }} type="primary" size="large">
								Save
							</Button>
						</div>
					</Form>
				</div>
			)
		}
		if (page === 'contact') {
			return (
				<div>
					<Form
						form={contactForm}
						initialValues={{
							text: '',
						}}
						onFinish={_onContactFinish}
						layout={'vertical'}
					>
						<Form.Item
							label={
								<label style={{ color: 'gray', fontSize: 12 }}>
									Leave us your questions and comments of the service, we will reply to you as soon as possible
								</label>
							}
							name="text"
						>
							<Input.TextArea placeholder={'Send a message'} />
						</Form.Item>
						<div style={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
							<Button style={{ width: '100%' }} size="large" onClick={() => setShowPanel(false)}>
								Discard
							</Button>
							<Button style={{ width: '100%' }} htmlType="submit" type="primary" size="large">
								Send
							</Button>
						</div>
					</Form>
				</div>
			)
		}
	}

	return (
		<Styled.Root role="banner">
			<div className="flex items-center w-full mx-auto justify-between">
				<a href="/app">
					<div className="h-full flex">
						<span className="text-[#5A47CD] text-2xl font-bold">Nebulas.ai</span>
					</div>
				</a>

				<div className="space-x-4 flex items-center">
					<Avatar size={36} style={{ backgroundColor: '#5A47CD', color: 'white' }}>
						{capitalize(user?.first_name.slice(0, 1))}
					</Avatar>
					<Button
						type="text"
						onClick={() =>
							setShowPanel({
								title: 'My Account',
								body: renderBody('profile'),
							})
						}
					>
						<Space>
							<Typography.Text>{capitalize(user?.first_name) + ' ' + capitalize(user?.last_name)}</Typography.Text>
							<DownOutlined className="text-[12px]" />
						</Space>
					</Button>
					<RiSettings3Line
						style={{ cursor: 'pointer' }}
						className="text-2xl"
						onClick={() => setShowPanel({ title: 'Settings', body: renderBody('settings') })}
					/>
					<RiNotification2Line className="text-2xl" />
					<RiQuestionLine
						style={{ cursor: 'pointer' }}
						className="text-2xl"
						onClick={() => setShowPanel({ title: 'Contact Us', body: renderBody('contact') })}
					/>
				</div>
			</div>
		</Styled.Root>
	)
}

export default AppHeader
