import { createSlice } from '@reduxjs/toolkit'

const knowledgeSlice = createSlice({
	name: 'Knowledge',
	initialState: { isReady: false },
	reducers: {
		startup(state) {},
		startupSuccess(state) {
			state.isReady = true
		},
		startupFailure() {},
	},
})

export const { startupSuccess, startup, startupFailure } = knowledgeSlice.actions

export default knowledgeSlice.reducer
