import { apiGPT } from './index'

export const ChatApi = apiGPT.injectEndpoints({
	endpoints: (build) => ({
		getConversations: build.query({
			providesTags: (params) => ['conversation'],
			query: (params) => ({
				url: '/conversations/',
				params,
			}),
		}),

		getContents: build.query({
			providesTags: (params) => ['contents'],
			query: (params) => ({
				url: '/contents/',
				params,
			}),
		}),

		putContent: build.mutation({
			invalidatesTags: (params) => ['contents'],
			query: ({ id, body }) => {
				return { url: `/contents/${id}`, method: 'PUT', body }
			},
		}),

		getModels: build.query({
			providesTags: () => ['models'],
			query: (params) => ({
				url: '/gpt/models',
			}),
		}),

		postConversations: build.mutation({
			invalidatesTags: () => ['conversation'],
			query: (body) => {
				return { url: `/conversations/`, method: 'POST', body }
			},
		}),

		putConversations: build.mutation({
			invalidatesTags: () => ['conversation'],
			query: ({ id, body }) => {
				return { url: `/conversations/${id}`, method: 'PUT', body }
			},
		}),

		deleteConversation: build.mutation({
			invalidatesTags: () => ['conversation'],
			query: (id) => {
				return { url: `/conversations/${id}`, method: 'DELETE' }
			},
		}),

		postToolsRenders: build.mutation({
			invalidatesTags: () => ['contents'],
			query: (body) => {
				return { url: `/tools/renders/`, method: 'POST', body }
			},
		}),

		postContacts: build.mutation({
			query: (body) => {
				return { url: `/contacts/`, method: 'POST', body }
			},
		}),
	}),
})

export const {
	useGetModelsQuery,
	useGetContentsQuery,
	useGetConversationsQuery,
	usePostToolsRendersMutation,
	usePutConversationsMutation,
	usePostConversationsMutation,
	useDeleteConversationMutation,
	usePutContentMutation,
	usePostContactsMutation,
} = ChatApi
