import { ArrowLeftOutlined } from '@ant-design/icons'
import { Breadcrumb, Skeleton, Tabs } from 'antd'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { useGetDocumentsQuery } from 'shared/api/documentApi'

import AddKnowledgeFormModal from 'modules/knowledge/components/modal/AddKnowledgeFormModal'
import AdvancedAnalystCard from 'modules/knowledge/components/card/AdvancedAnalystCard'
import ContentCard from 'modules/knowledge/components/card/ContentCard'

import emptyIcon from 'assets/images/empty.svg'

const KnowledgeCategoryPage = () => {
	const [selectItem, setSelectItem] = useState([])

	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const { data: documentData, isFetching: isGetDocumentFetching } = useGetDocumentsQuery(
		{ params: searchParams, string: searchParams.toString() },
		{
			refetchOnMountOrArgChange: true,
		}
	)

	const _onSelectClick = (value) => {
		if (selectItem.map((i) => i.id).includes(value.id)) {
			setSelectItem((prevState) => prevState.filter((v) => v.id !== value.id))
		} else {
			setSelectItem((prevState) => [...prevState, value])
		}
	}

	return (
		<div>
			<div className="card pt-4 px-6 relative">
				<div className="absolute space-x-4 right-8 flex items-center">
					<AddKnowledgeFormModal />
					{/*<Button className="flex items-center">*/}
					{/*	<ShareAltOutlined />*/}
					{/*	Send a request*/}
					{/*</Button>*/}
				</div>
				<Breadcrumb
					items={[
						{ title: <Link to="/app/knowledge">Knowledge base</Link> },
						{
							title: <span className="capitalize">{searchParams.get('access_level')}</span>,
						},
					]}
				/>
				<Link to="/app/knowledge" className="flex items-center space-x-2 my-2">
					<ArrowLeftOutlined className="text-[#000]" />
					<span className="text-xl text-[#000]">Documents</span>
				</Link>
				<Tabs
					activeKey={searchParams.get('access_level') || 'all'}
					onChange={(key) => {
						searchParams.set('access_level', key)
						navigate({
							pathname: '',
							search: searchParams.toString(),
						})
					}}
					items={[
						{ key: 'all', label: `All` },
						{ key: 'public', label: `Public` },
						{ key: 'team', label: `Team` },
						{ key: 'personal', label: `Personal` },
					]}
				/>
			</div>
			<div className="max-w-[792px] m-auto">
				<div className="text-xl px-6 py-4">
					{isGetDocumentFetching ? <Skeleton.Button /> : documentData?.length} documents
				</div>
				<div className="space-y-4">
					{documentData?.map((item, i) => (
						<ContentCard
							data={item}
							key={i}
							onSelect={_onSelectClick}
							isSelect={selectItem?.map((i) => i.id).includes(item.id)}
						/>
					))}
				</div>

				{!isGetDocumentFetching && documentData?.length === 0 && (
					<div className="bg-white text-center py-36">
						<img src={emptyIcon} alt="emptyIcon" className="m-auto mb-4" />
						<div>There is no record for now</div>
					</div>
				)}
			</div>
			<AdvancedAnalystCard items={selectItem} setSelectItem={setSelectItem} />
		</div>
	)
}

export default KnowledgeCategoryPage

KnowledgeCategoryPage.propTypes = {
	/** Content to display if there are no errors. */
	children: PropTypes.node,
}

KnowledgeCategoryPage.defaultProps = {
	children: null,
}
