import { Button, Divider, Form, Input, Select, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { presetPalettes } from '@ant-design/colors'
import React from 'react'

const PromptFormItems = ({ form }) => {
	return (
		<Form.List name="placeholders">
			{(fields, { add, remove }) => (
				<>
					<div className="border boder-solid border-t-1">
						{fields.map((field) => (
							<Form.Item key={field.key} isListField noStyle>
								<Divider />
								<div className="flex items-center justify-between">
									<div className="text-base font-medium">Field</div>
									<Button type="link" onClick={() => remove(field.name)}>
										Delete
									</Button>
								</div>
								<div>
									<Form.Item name={[field.name, 'name']} label={'Title'}>
										<Input />
									</Form.Item>
									<Form.Item name={[field.name, 'data_type']} label={'Field type'}>
										<Select
											options={[
												{ value: 'dt_string', label: 'String' },
												{ value: 'dt_multi_selections', label: 'Multi Selections' },
												{ value: 'dt_single_selections', label: 'Single Selections' },
											]}
										/>
									</Form.Item>
									<Form.Item name={[field.name, 'description']} label={'Description'}>
										<Input.TextArea />
									</Form.Item>
									<Form.List name={[field.name, 'data_values']}>
										{(dataFields, { add: dataAdd, remove: dataRemove }) => (
											<>
												<Form.Item label={'Data Values'}>
													{dataFields.map((dataField) => (
														<Tag
															key={dataField.key}
															style={{ fontSize: '16px' }}
															color={Object.values(presetPalettes)[dataField.key + 5][5]}
															closable
															onClose={() => remove(dataField.name)}
														>
															{form.getFieldsValue()?.['placeholders'][field.name]['data_values'][dataField.name]}
														</Tag>
													))}
												</Form.Item>
												<Form.Item label={' '} colon={false}>
													<div className="flex items-center space-x-2">
														<Input />
														<Button>Add item</Button>
													</div>
												</Form.Item>
											</>
										)}
									</Form.List>
									<Form.Item name={[field.name, 'default_value']} label={'Default Value'}>
										<Input />
									</Form.Item>
								</div>
							</Form.Item>
						))}

						<Divider />
						<Form.Item>
							<Button type="link" className="flex items-center" onClick={() => add('')}>
								<PlusOutlined className="text-[20px]" />
								Add a new field
							</Button>
						</Form.Item>
					</div>
				</>
			)}
		</Form.List>
	)
}

export default PromptFormItems
