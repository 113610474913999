import { Layout as _Layout } from 'antd'
import styled from '@xstyled/styled-components'

export const Root = styled.div`
	padding: 16px;
	max-height: calc(100vh - 64px);
	height: 100%;
	.ant-layout {
		height: 100%;
		max-height: calc(100vh - 91px);
	}
`

export const Layout = styled(_Layout)`
	max-height: calc(100vh - 91px);

	.ant-layout-header {
		background-color: white;
		padding: 0 24px !important;
		border-top-right-radius: 8px;
		border: 1px solid #0000000f;
	}
	.ant-layout-footer {
		border: 1px solid #0000000f;
		background-color: white;
		padding: 16px 24px !important;
		border-bottom-right-radius: 8px;
	}
	.ant-layout-content {
		overflow: auto;

		border: 1px solid #0000000f;
		background-color: white;
	}
	.ant-layout-sider {
		overflow: auto;

		border-bottom-left-radius: 8px;
		border-top-left-radius: 8px;
		padding: 8px 24px;
		border: 1px solid #0000000f;
	}
`
